import React, { useEffect, useState } from "react";
import { urlGenerator } from "../../../../Utils";
import CustomerFormUrl from "../CommonForms/CustomerFormUrl";
import CustomerFormRemoveCustomer from "./CustomerFormRemoveCustomer";

interface IProps {
  defaultData?: {
    customerName: string;
    customerUrl: string;
    hourlyRate: string;
    customerDescription: string;
    customerKey?: string;
  };
  saveDataAction: (
    customerName: string,
    customerDescription: string,
    hourlyRate: string,
    customerUrl: string
  ) => void;
  saveDataTxt: string;
  cancelAction?: () => void;
  removeCustomer?: () => void;
}

const CustomerForm = ({
  defaultData,
  saveDataAction,
  saveDataTxt,
  cancelAction,
  removeCustomer,
}: IProps) => {
  const [customerName, setCustomerName] = useState("");
  const [customerHourlyRate, setCustomerHourlyRate] = useState("");
  const [customerDescription, setCustomerDescription] = useState("");
  const [customerUrl, setCustomerUrl] = useState("");

  useEffect(() => {
    if (!defaultData) return;
    setCustomerName(defaultData.customerName);
    setCustomerUrl(defaultData.customerUrl);
    setCustomerDescription(defaultData.customerDescription);
    setCustomerHourlyRate(defaultData.hourlyRate);
  }, [defaultData]);

  const updateCustomerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCustomerName(value);
    if (defaultData && defaultData.customerKey) return; // dont update url for existing customer
    // also update customer url
    const url = urlGenerator(value, customerUrl);
    setCustomerUrl(url);
  };

  const updateHourlyRate = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(",", ".");
    if (parseFloat(value) > 99999) return;
    if (value.indexOf(".") !== -1) {
      const divided = value.split(".");
      value = divided[0] + "." + divided[1].slice(0, 2);
    }
    setCustomerHourlyRate(value);
  };
  const updateCustomerDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => setCustomerDescription(event.target.value);

  const allowSubmit = () => customerName.length > 0;

  const saveData = () =>
    saveDataAction(
      customerName,
      customerDescription,
      customerHourlyRate,
      customerUrl
    );

  return (
    <form className="">
      <div className="form-group ">
        <label htmlFor="customer-name">Customer name</label>
        <input
          type="text"
          className="form-control"
          id="customer-name"
          onChange={updateCustomerName}
          value={customerName}
          required
          maxLength={128}
        />
      </div>
      <CustomerFormUrl
        url={customerUrl}
        setUrl={setCustomerUrl}
        customerKey={defaultData?.customerKey}
      />
      <div className="form-group">
        <label htmlFor="customer-default-hourly-rate">
          Default hourly rate
        </label>
        <input
          type="number"
          step="0.01"
          className="form-control"
          id="customer-default-hourly-rate"
          onChange={updateHourlyRate}
          value={customerHourlyRate}
        />
      </div>
      <div className="form-group">
        <label htmlFor="customer-description">Additional description</label>
        <textarea
          className="form-control"
          id="customer-description"
          rows={2}
          onChange={updateCustomerDescription}
          value={customerDescription}
        ></textarea>
      </div>

      <div className="mt-3 d-flex">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={saveData}
          disabled={!allowSubmit()}
        >
          {saveDataTxt}
        </button>
        {cancelAction && (
          <button className="btn btn-link btn-sm ml-3" onClick={cancelAction}>
            Cancel
          </button>
        )}
        {removeCustomer && (
          <CustomerFormRemoveCustomer removeCustomer={removeCustomer} />
        )}
      </div>
    </form>
  );
};

export default CustomerForm;
