import React from "react";

interface IProps {
  hideTaskControls?: boolean;
  customFirstHeader?: JSX.Element;
}
const TaskListHeader = ({ hideTaskControls, customFirstHeader }: IProps) => (
  <thead className="bg-200 text-800">
    <tr>
      {customFirstHeader}
      <th className="text-nowrap table-name-heading">Task</th>
      <th className="text-nowrap text-center">Hourly rate</th>
      <th className="text-nowrap text-center">Total time spent</th>
      <th className="text-nowrap text-center">Earnings</th>
      {!hideTaskControls && <th className="text-nowrap text-center"></th>}
    </tr>
  </thead>
);
export default TaskListHeader;
