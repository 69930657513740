import React from "react";
import { Task } from "../../../../../store/data/types";
import ManageExistingReportsItemDetailsReportTaskItem from "./ManageExistingReportsItemDetailsReportTaskItem";

interface IProps {
  reportTasksList: Task[];
}

const ManageExistingReportsItemDetailsReport = ({
  reportTasksList,
}: IProps) => {
  const renderProjectItem = (task: Task) => (
    <ManageExistingReportsItemDetailsReportTaskItem task={task} />
  );
  return (
    <>
      <div className="mb-1 small">Task list:</div>
      <table className="table table-responsive small">
        <tbody>{reportTasksList.map(renderProjectItem)}</tbody>
      </table>
    </>
  );
};

export default ManageExistingReportsItemDetailsReport;
