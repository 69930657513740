import React, { useEffect, useState } from "react";
import { API_getReportList } from "../../../../services/reports";

import { Report } from "../../../../store/reports/types";
import Loader from "../../../Loader";
import ManageReports from "./ManageReports";
import TasksNoReports from "./TasksNoReports";

const Reports = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState("");

  const addNewReport = (newReport: Report) =>
    setReports([...reports, newReport]);
  const updateReport = (updatedReport: Report) =>
    setReports(
      reports.map((r) =>
        r.reportKey === updatedReport.reportKey ? updatedReport : r
      )
    );

  useEffect(() => {
    API_getReportList()
      .then((response) => {
        setReports(response.data);
      })
      .then(() => setLoader(false))
      .catch((errorMessage) => {
        setLoader(false);
        setError(errorMessage);
      });
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          {error.length > 0 && (
            <div className="card bg-white border-light">
              <div className="card-body p-4">
                <div className="alert alert-danger">{error}</div>
              </div>
            </div>
          )}
          {loader ? (
            <Loader />
          ) : (
            <>
              <TasksNoReports
                reports={reports}
                addNewReport={addNewReport}
                updateReport={updateReport}
              />
              <ManageReports reports={reports} updateReport={updateReport} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reports;
