import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

const MAX_HEIGHT = 30;

interface IProps {
  text: string;
}

const ManageExistingReportsItemDetailsLongText = ({ text }: IProps) => {
  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => setShowAll(!showAll);

  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) setHeight(ref.current.clientHeight);
  }, []);

  if (!text.length) return null;

  if( text.length < 100 ) return <div>{text}</div>

  return (
    <>
      <div ref={ref} className={showAll ? "" : "long-text-cutted"}>
        {text.split("\n").map((txt, idx) => (
          <div key={`txt_${idx}`}>{txt}</div>
        ))}
      </div>
      {height > MAX_HEIGHT && (
        <button
          onClick={toggleShowAll}
          className="btn btn-sm btn-link text-center btn-block text-muted show-more-task-times-btn border-0 mb-3"
        >
          {showAll ? (
            <>
              Less <FontAwesomeIcon icon={faArrowUp} />
            </>
          ) : (
            <>
              More <FontAwesomeIcon icon={faArrowDown} />
            </>
          )}
        </button>
      )}
    </>
  );
};

export default ManageExistingReportsItemDetailsLongText;
