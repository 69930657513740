import {
  ADD_CUSTOMERS,
  ADD_TASKS,
  ADD_TASK_TIME,
  Customer,
  EDIT_CUSTOMER,
  EDIT_TASK,
  EDIT_TASK_TIME,
  REMOVE_CUSTOMER,
  REMOVE_TASKS_BY_KEY,
  REMOVE_TASKS_TIMES,
  Task,
  TaskTime,
} from "./types";

export const addCustomers = (customers: Customer[]) => ({
  type: ADD_CUSTOMERS,
  payload: customers,
});
export const editCustomer = (customer: Customer) => ({
  type: EDIT_CUSTOMER,
  payload: customer,
});
export const RemoveCustomerByKey = (customerKey: string) => ({
  type: REMOVE_CUSTOMER,
  payload: customerKey,
})

export const addTasks = (tasks: Task[]) => ({
  type: ADD_TASKS,
  payload: tasks,
});
export const editTask = (task: Task) => ({
  type: EDIT_TASK,
  payload: task,
});
export const removeTasksByKey = (taskKeys: string[]) => ({
  type: REMOVE_TASKS_BY_KEY,
  payload: taskKeys,
});

export const addTaskTime = (taskId: string, taskTime: TaskTime) => ({
  type: ADD_TASK_TIME,
  payload: { taskId, taskTime },
});
export const editTaskTime = (taskId: string, taskTime: TaskTime) => ({
  type: EDIT_TASK_TIME,
  payload: { taskId, taskTime },
});

export const removeTaskTimes = (
  removedTaskTimes: { taskKey: string; taskTimeKey: string }[]
) => ({
  type: REMOVE_TASKS_TIMES,
  payload: removedTaskTimes,
});
