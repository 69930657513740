import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { API_checkCustomerNameAvailability } from "../../../../services/customers";
import Loader from "../../../Loader";

interface IProps {
  url: string;
  setUrl: (url: string) => void;
  customerKey?: string;
}

const FormUrl = ({ url, setUrl, customerKey }: IProps) => {
  const [editEnabled, setEditEnabled] = useState(false);
  const toggleEnabled = () => setEditEnabled(!editEnabled);

  let timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const prevUrlRef = useRef<string>();

  const [urlChecking, setUrlChecking] = useState(false);
  const [urlUpdatedMessage, setUrlUpdatedMessage] = useState("");
  const [urlUpdatedManually, setUrlUpdatedManually] = useState(false);

  const updateUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value.toUpperCase());
    setUrlUpdatedManually(true);
  };

  useEffect(() => {
    if (!url.length) return;
    if (prevUrlRef.current && prevUrlRef.current === url) {
      // info: when user manually updated from AB-2 to AB, then backend send correct AB-2 and then to prevent re-check - return
      return;
    } else {
      prevUrlRef.current = url;
    }

    const timeoutTime = urlUpdatedManually ? 1000 : 500;
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setUrlChecking(true);
      const request = API_checkCustomerNameAvailability(url, customerKey)

      request
        .then((response) => {
          const { otherUrlProposal, status } = response.data;
          if (status === "taken" && otherUrlProposal !== url) {
            setUrl(otherUrlProposal);
            prevUrlRef.current = otherUrlProposal;
            if (urlUpdatedManually) {
              setUrlUpdatedMessage(
                `Url ${url} was already taken. It has been automatically updated.`
              );
              setTimeout(() => setUrlUpdatedMessage(""), 2000);
            }
          }
          setUrlUpdatedManually(false);
        })
        .finally(() => {
          setUrlChecking(false);
        });
    }, timeoutTime);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, urlUpdatedManually]);

  return (
    <div className="form-group ">
      <label htmlFor="custom-url">Internal url</label>
      <div className="d-flex align-items-center">
        <input
          type="text"
          className="form-control form-control-sm"
          id="custom-url"
          onChange={updateUrl}
          value={url}
          required
          maxLength={64}
          disabled={!editEnabled}
        />
        {!editEnabled && (
          <button onClick={toggleEnabled} className="btn btn-sm">
            <FontAwesomeIcon icon={faEdit} />
          </button>
        )}
        {urlChecking && <Loader small customClassName="ml-2" />}
      </div>
      {urlUpdatedMessage.length > 0 && (
        <div className="form-text small">{urlUpdatedMessage}</div>
      )}
    </div>
  );
};

export default FormUrl;
