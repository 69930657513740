import React from "react";

const LOGO = require("../../../assets/illustrations/logo-star.png");

interface IProps {
  toggleMobileMenuVisible: () => void;
}

const MobileHeader = ({ toggleMobileMenuVisible }: IProps) => (
  <nav className="navbar navbar-dark navbar-theme-primary col-12 d-md-none">
    <a className="navbar-brand mr-lg-5" href="../../index.html">
      <img className="" src={LOGO} alt="Workstmt.com" />
    </a>
    <div className="slogan-mobile-header text-white">Workstmt</div>
    <div className="d-flex align-items-center">
      <button
        className="navbar-toggler d-md-none collapsed"
        type="button"
        onClick={toggleMobileMenuVisible}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
    </div>
  </nav>
);

export default MobileHeader;
