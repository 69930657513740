import axios from "axios";
import { API } from "../config/constants";
import { User, UserBilling } from "../store/user/types";
import "./helper";

export const API_userInfo = () =>
  axios.post<User>(`${API}user`, {
    action: "userinfo",
  });

export const API_editUserData = (userName: string, email: string) =>
  axios.post<User>(`${API}user`, {
    action: "edit_user_data",
    userName,
    email,
  });

export const API_editUserBilling = (userBilling: UserBilling) =>
  axios.post<User>(`${API}user`, {
    action: "edit_user_billing",
    userBilling,
  });
