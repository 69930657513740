import { useRef, useState } from "react";
import { API_editTaskTime } from "../../../../services/tasks";
import { useAppDispatch } from "../../../../store";
import { TaskTime } from "../../../../store/data/types";
import { editTaskTime } from "../../../../store/data/actions";
import Loader from "../../../Loader";
import React from "react";

interface IProps {
  taskTime: TaskTime;
  taskKey: string;
}

const TaskTimeEditAmount = ({ taskTime, taskKey }: IProps) => {
  const dispatch = useAppDispatch();
  let timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showSavedMessage, setShowSavedMessage] = useState(false);

  const updateAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(",", ".");
    if (parseFloat(value) > 99999) return;
    if (value.indexOf(".") !== -1) {
      const divided = value.split(".");
      value = divided[0] + "." + divided[1].slice(0, 2);
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      saveAmount(value);
    }, 400);
  };

  const saveAmount = (updatedAmount: string) => {
    setLoading(true);
    API_editTaskTime(
      taskTime.taskTimeKey,
      taskTime.start,
      taskTime.stop,
      taskTime.description,
      updatedAmount
    )
      .then((response) => {
        dispatch(editTaskTime(taskKey, response.data));
        setShowSavedMessage(true);
        setTimeout(() => setShowSavedMessage(false), 1600);
      })
      .catch((e) => {
        setError(e);
        setTimeout(() => setError(""), 4000);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      {error.length > 0 && (
        <div className="alert alert-danger p-1">
          <small>{error}</small>
        </div>
      )}
      <input
        className="form-control form-control-sm"
        type="number"
        defaultValue={taskTime.amount !== null ? taskTime.amount : 0}
        onChange={updateAmount}
        placeholder="0"
      />
      <div className="small d-flex justify-content-end align-items-center text-muted save-task-time-description-feedback">
        {showSavedMessage && <span>Amount saved</span>}
        {loading && (
          <>
            <Loader small /> <span className="ml-2">saving...</span>
          </>
        )}
      </div>
    </div>
  );
};

export default TaskTimeEditAmount;
