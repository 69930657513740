import React from "react";
import { useState } from "react";

interface IProps {
  removeCustomer?: () => void;
}

const CustomerFormRemoveCustomer = ({ removeCustomer }: IProps) => {
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const toggleRemoveConfirmation = () =>
    setRemoveConfirmation(!removeConfirmation);

  if (!removeConfirmation)
    return (
      <button
        className="btn btn-sm btn-outline-dribbble py-1 px-1 ml-auto"
        onClick={toggleRemoveConfirmation}
      >
        <small>Remove customer</small>
      </button>
    );

  return (
    <div className="ml-auto">
      <span className="mr-2">
        <small>Do you really want to remove this customer?</small>
      </span>
      <button
        className="btn btn-sm btn-outline-dribbble py-1 px-1 mr-2"
        onClick={removeCustomer}
      >
        <small>Yes</small>
      </button>
      <button
        className="btn btn-sm btn-outline-info py-1 px-1"
        onClick={toggleRemoveConfirmation}
      >
        <small>Cancel</small>
      </button>
    </div>
  );
};

export default CustomerFormRemoveCustomer;
