import React, { useState } from "react";
import { faEllipsisH, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_addNewTaskTime } from "../../../../services/tasks";
import { useAppDispatch } from "../../../../store";
import { addTaskTime } from "../../../../store/data/actions";
import Loader from "../../../Loader";
import TaskListItemAddTimeMoreOptions from "./TaskListItemAddTimeMoreOptions";
import { TaskTimeType } from "../../../../store/data/types";

interface IProps {
  taskKey: string;
}

const TaskListItemAddTime = ({ taskKey }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const toggleMoreMenu = () => setShowMoreMenu(!showMoreMenu);
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();

  const addTaskTimeAction = (type: TaskTimeType, amount?: string, description?: string) => {
    setLoading(true);
    setShowMoreMenu(false);
    API_addNewTaskTime(taskKey, type, amount, description)
      .then((response) => {
        dispatch(addTaskTime(taskKey, response.data));
      })
      .catch((e) => {
        setError(e);
        setTimeout(() => setError(""), 4000);
      })
      .finally(() => setLoading(false));
  };

  const addTaskTimeItem = () => addTaskTimeAction("TIME");

  return loading ? (
    <Loader />
  ) : (
    <div className="d-flex justify-content-end position-relative toggle-menu-container">
      {error.length > 0 && (
        <div className="alert alert-danger p-0">
          <small>{error}</small>
        </div>
      )}
      <button
        className="btn btn-sm btn-light py-1 px-1 d-flex toggle-menu-container-selector"
        onClick={addTaskTimeItem}
      >
        <small>Add time</small>{" "}
        <FontAwesomeIcon icon={faPlus} size="1x" className="ml-1" />
      </button>
      <div>
        <button
          className="btn btn-sm btn-outline-light py-1 px-1 d-flex ml-2"
          onClick={toggleMoreMenu}
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        {showMoreMenu && (
          <TaskListItemAddTimeMoreOptions
            toggleMoreMenu={toggleMoreMenu}
            addTaskTimeAction={addTaskTimeAction}
          />
        )}
      </div>
    </div>
  );
};

export default TaskListItemAddTime;
