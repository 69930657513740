import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import CustomerList from "./Customer/CustomerList";
import TaskList from "./Task/TaskList";

const Dashboard = () => (
  <div className="container-fluid">
    <div className="row mb-2">
      <div className="col-md-12">
        <div className="card bg-white border-light">
          <div className="card-body p-4">
            <TaskList displayCustomerName={true} />
            <Link to="/app/add-task" className="btn btn-sm btn-light">
              Add a new task <FontAwesomeIcon icon={faPlus} />
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div className="row mb-2">
      <div className="col-md-12">
        <div className="card bg-white border-light">
          <div className="card-body p-4">
            <CustomerList />
            <Link to="/app/add-customer" className="btn btn-sm btn-light">
              Add a new customer <FontAwesomeIcon icon={faPlus} />
            </Link>
          </div>
        </div>
      </div>
    </div>


  </div>
);

export default Dashboard;
