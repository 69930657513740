import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API_registerUser } from "../../services/auth";
import { setUser } from "../../store/user/actions";
import { emailIsValid } from "../../Utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faEye,
  faUnlockAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const RegisterForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(true);
  const [error, setError] = useState("");

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const allowSubmit = () =>
    password.length >= 6 &&
    name.length > 0 &&
    emailIsValid(email) &&
    termsAndConditions;

  const signUp = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setError("");
    API_registerUser(email, password, name)
      .then((response) => {
        dispatch(setUser(response.data.user));
        navigate("/app");
      })
      .catch((errorMessage) => {
        setError(errorMessage);
      });
  };

  const setupPassword = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);
  const setupEmail = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);
  const setupName = (event: React.ChangeEvent<HTMLInputElement>) =>
    setName(event.target.value);
  const setupTermsAndConditions = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setTermsAndConditions(event.target.checked);

  return (
    <section className="vh-100 bg-soft d-flex align-items-center">
      <div className="container">
        <div className="row justify-content-center form-bg-image signup-container">
          <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="signin-inner mt-3 mt-lg-0 bg-white shadow-soft border border-light rounded p-4 p-lg-5 w-100 fmxw-500">
              <div className="text-center text-md-center mb-4 mt-md-0">
                <h1 className="h3 font-weight-bold mb-3">Create an account</h1>
              </div>
              {error.length > 0 && (
                <div className="alert alert-danger">{error}</div>
              )}
              <form>
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your name"
                      onChange={setupName}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faAt} />
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                      onChange={setupEmail}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </span>
                    </div>
                    <input
                      className="form-control"
                      placeholder="Password"
                      type={passwordShown ? "text" : "password"}
                      onChange={setupPassword}
                      minLength={6}
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={togglePasswordVisiblity}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="d-block d-sm-flex justify-content-between align-items-center mt-2">
                    <div className="form-group form-check mt-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="terms-and-conditions"
                        checked={termsAndConditions}
                        onChange={setupTermsAndConditions}
                      />
                      <label
                        className="form-check-label form-check-sign-white"
                        htmlFor="terms-and-conditions"
                      >
                        I agree to the{" "}
                        <Link className="font-weight-bold" to="/terms">
                          terms and conditions
                        </Link>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <button
                    type="submit"
                    className="btn btn-block btn-primary"
                    onClick={signUp}
                    disabled={!allowSubmit()}
                  >
                    Sign up
                  </button>
                </div>
              </form>
              <div className="d-block d-sm-flex justify-content-center align-items-center mt-4">
                <span className="font-weight-normal">
                  Already have an account?{" "}
                  <Link className="font-weight-bold" to="/login/signin">
                    Login here
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterForm;
