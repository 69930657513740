import React, { useMemo, useState } from "react";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Task, TaskTime } from "../../../../store/data/types";
import { DASHBOARD_DEFAULT_TASK_TIME_ITEMS } from "../../../../Utils/constants";
import TaskActiveTimeListItem from "./TaskActiveTimeListItem";
import TaskActiveTimeListItemHeader from "./TaskActiveTimeListItemHeader";

interface IProps {
  task: Task;
  showAllTasksForReport?: boolean;
}

const TaskActiveTimeList = ({ task, showAllTasksForReport }: IProps) => {
  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => setShowAll(!showAll);

  const { taskTimes } = task;
  const activeTaskTimes = useMemo(() => {
    // for eg report - show all tasks
    if (showAllTasksForReport) return taskTimes;
    // no stop or from last 1hr
    return taskTimes.filter(
      (taskTime) =>
        !taskTime.stop ||
        new Date().getTime() - taskTime.stop * 1000 < 3600 * 1000
    );
  }, [showAllTasksForReport, taskTimes]);

  const renderTaskItem = (taskTime: TaskTime, index: number) =>
    index > DASHBOARD_DEFAULT_TASK_TIME_ITEMS - 1 && !showAll ? null : (
      <TaskActiveTimeListItem
        key={`task_time_item_${taskTime.taskTimeKey}`}
        task={task}
        taskTime={taskTime}
        noTimeEdit={showAllTasksForReport}
      />
    );

  if (!activeTaskTimes.length) return null;
  return (
    <tr className="task-list-row">
      <td colSpan={5}>
        <div className="task-list-row-outer">
          <table className="table table-responsive freelance-table freelance-table-sm">
            <TaskActiveTimeListItemHeader />
            <tbody>{activeTaskTimes.map(renderTaskItem)}</tbody>
          </table>
          {activeTaskTimes.length > DASHBOARD_DEFAULT_TASK_TIME_ITEMS && (
            <button
              onClick={toggleShowAll}
              className="btn btn-sm btn-link text-center btn-block text-muted show-more-task-times-btn border-0"
            >
              {showAll ? (
                <>
                  Less <FontAwesomeIcon icon={faArrowUp} />
                </>
              ) : (
                <>
                  More <FontAwesomeIcon icon={faArrowDown} />
                </>
              )}
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default TaskActiveTimeList;
