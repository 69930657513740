import { useAppDispatch, useAppSelector } from "../../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useEffect } from "react";
import { API_editUserData } from "../../../../services/user";
import { setUser } from "../../../../store/user/actions";
import Loader from "../../../Loader";
import { Link } from "react-router-dom";

const BaseUserDataForm = () => {
  const dispatch = useAppDispatch();
  const { details } = useAppSelector((app) => app.user);

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userEmailConfirm, setUserEmailConfirm] = useState("");

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  const updateUserName = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUserName(e.target.value);
  const updateUserEmail = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUserEmail(e.target.value);
  const updateUserEmailConfirm = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUserEmailConfirm(e.target.value);

  useEffect(() => {
    setUserName(details.userName);
    setUserEmail(details.email);
  }, [details]);

  const allowSubmit = () =>
    userName.length && userEmail.length && userEmail === userEmailConfirm;

  const saveUserData = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoader(true);
    API_editUserData(userName, userEmail)
      .then((response) => {
        dispatch(setUser(response.data));
        setError("");
        setLoader(false);
      })
      .catch((errorMessage) => {
        setError(errorMessage);
        setLoader(false);
      });
  };

  if (loader) return <Loader />;
  return (
    <>
      <div className="card bg-white border-light p-3 mb-4">
        <div className="card-body p-4">
          <div className="mb-3">
            <h4>Hi, {details.userName}</h4>
            Your account type: <strong>{details.accountType}</strong>{" "}
            {details.accountType === "standard" && (
              <Link to="/app/account/upgrade">
                check why and upgrade to Pro!
              </Link>
            )}
            {details.accountType === "standard" && (
              <div>
                <Link className="small" to="/app/account/upgrade">
                  Upgrade to be able to add more clients and tasks!
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="card bg-white border-light p-3 mb-4">
        {error.length > 0 && <div className="alert alert-danger">{error}</div>}
        <div className="card-body p-4">
          <h4 className="mb-3">About you</h4>
          <form onSubmit={saveUserData}>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label className="form-label h6" htmlFor="firstNameLabel">
                    Full Name <span className="text-danger">*</span>
                  </label>
                  <div className="input-group mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faUserAlt} />
                      </span>
                    </div>
                    <input
                      className="form-control"
                      id="firstNameLabel"
                      placeholder="Full Name"
                      type="text"
                      value={userName}
                      onChange={updateUserName}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label className="form-label h6" htmlFor="EmailLabel">
                    Email <span className="text-danger">*</span>
                  </label>
                  <div className="input-group mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </span>
                    </div>
                    <input
                      className="form-control"
                      id="EmailLabel"
                      placeholder="Your Email"
                      type="email"
                      value={userEmail}
                      onChange={updateUserEmail}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label className="form-label h6" htmlFor="ConfirmEmailLabel">
                    {" "}
                    Confirm Email <span className="text-danger">*</span>{" "}
                    <span className="small">(Just to ensure delivery)</span>
                  </label>
                  <div className="input-group mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </span>
                    </div>
                    <input
                      className="form-control"
                      id="ConfirmEmailLabel"
                      placeholder="Confirm email?"
                      type="email"
                      value={userEmailConfirm}
                      onChange={updateUserEmailConfirm}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row text-right">
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  disabled={!allowSubmit()}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BaseUserDataForm;
