import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TooltipModel,
  TooltipItem,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { UserStats, YearlyStats } from "../../../../store/statistics/types";
import { getEarningsByTime } from "../../../../Utils";
import MONTHS from "./months.json";

interface ChartDataDatasets {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const labels = MONTHS.map((month) => month.name);
const chartColors = [
  "rgba(255, 99, 132, 0.2)",
  "rgba(255, 159, 64, 0.2)",
  "rgba(255, 205, 86, 0.2)",
  "rgba(75, 192, 192, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(153, 102, 255, 0.2)",
  "rgba(201, 203, 207, 0.2)",
];
const borderColor = [
  "rgba(255, 99, 132, 0.4)",
  "rgba(255, 159, 64, 0.4)",
  "rgba(255, 205, 86, 0.4)",
  "rgba(75, 192, 192, 0.4)",
  "rgba(54, 162, 235, 0.4)",
  "rgba(153, 102, 255, 0.4)",
  "rgba(201, 203, 207, 0.4)",
];

const mapDataToChart = (data: YearlyStats) => {
  const chartData: ChartDataDatasets[] = [];
  const customerKeys: string[] = [];
  const customerSet: { key: string; name: string }[] = [];
  Object.values(data).forEach((monthData) =>
    monthData.forEach((month: UserStats) => {
      if (!customerKeys.includes(month.customerKey)) {
        customerKeys.push(month.customerKey);
        customerSet.push({ key: month.customerKey, name: month.customerName });
      }
    })
  );
  let idx = 0;
  for (const customerKey of customerKeys) {
    const dataset: number[] = [];
    for (let i = 1; i <= 12; i++) {
      const customerTasksMonts = data[i].find(
        (set) => set.customerKey === customerKey
      );
      const money = customerTasksMonts
        ? customerTasksMonts.tasks.reduce((prev, next) => {
            const earnings = next.amount
              ? next.amount
              : getEarningsByTime(next.hourlyRate, next.time);
            return prev + earnings;
          }, 0)
        : 0;

      dataset.push(money);
    }
    const customerName =
      customerSet.find((c) => c.key === customerKey)?.name || "";
    chartData.push({
      label: customerName,
      data: dataset,
      backgroundColor: chartColors[idx] || "rgba(201, 203, 207, 0.2)",
      borderColor: borderColor[idx] || "rgba(201, 203, 207, 0.2)",
      borderWidth: 1,
    });
    idx += 1;
  }
  return chartData;
};

interface IProps {
  statsYearly: YearlyStats;
}

function ChartComponent({ statsYearly }: IProps) {
  const datasets = mapDataToChart(statsYearly);

  const data = {
    labels,
    datasets,
  };
  console.log(datasets);
  return (
    <div style={{ width: "99%", minHeight: "30rem" }}>
      <Bar
        options={{
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: `Earnings statistics for ${new Date().getFullYear()}`,
            },
            tooltip: {
              callbacks: {
                label: function (
                  this: TooltipModel<"bar">,
                  tooltipItem: TooltipItem<"bar">
                ) {
                  // disable '0' value in the tooltip
                  if (
                    this.dataPoints[tooltipItem.datasetIndex]
                      ?.formattedValue === "0"
                  )
                    return "";
                },
              },
            },
          },
          responsive: true,
          interaction: {
            mode: "index" as const,
            intersect: false,
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        }}
        data={data}
      />
    </div>
  );
}
export default ChartComponent;
