import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { urlGenerator } from "../../../../Utils";
import TaskFormUrl from "../CommonForms/TaskFormUrl";
import TaskFormCustomerSelect from "./TaskFormCustomerSelect";
import TaskFormRemoveTask from "./TaskFormRemoveTask";

interface IProps {
  defaultData?: {
    taskName: string;
    taskHourlyRate: string;
    taskDescription: string;
    taskUrl: string;
    customerKey: string;
    taskKey: string;
  };
  saveTaskAction: (
    customerKey: string,
    name: string,
    hourlyRate: string,
    description: string,
    taskUrl: string
  ) => void;
  removeTask?: () => void;
  saveDataTxt: string;
  cancelAction?: () => void;
}

const TaskForm = ({
  defaultData,
  saveTaskAction,
  removeTask,
  saveDataTxt,
  cancelAction,
}: IProps) => {
  const { customers } = useAppSelector((app) => app.data);

  const [taskName, setTaskName] = useState("");
  const [taskUrl, setTaskUrl] = useState("");
  const [taskHourlyRate, setTaskHourlyRate] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [customerKey, setCustomerKey] = useState("");

  useEffect(() => {
    if (!defaultData) return;

    setTaskName(defaultData.taskName);
    setTaskHourlyRate(defaultData.taskHourlyRate);
    setTaskDescription(defaultData.taskDescription);
    setCustomerKey(defaultData.customerKey);
    setTaskUrl(defaultData.taskUrl);
  }, [defaultData]);

  const updateTaskName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setTaskName(value);
    if (defaultData && defaultData.customerKey) return; // dont update url for existing customer
    // also update customer url
    const url = urlGenerator(value, taskUrl);
    setTaskUrl(url);
  };
  const updateHourlyRate = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(",", ".");
    if (parseFloat(value) > 99999) return;
    if (value.indexOf(".") !== -1) {
      const divided = value.split(".");
      value = divided[0] + "." + divided[1].slice(0, 2);
    }
    setTaskHourlyRate(value);
  };
  const updateTaskDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => setTaskDescription(event.target.value);

  const updateCustomerKey = (selectedCustomerKey: string) => {
    const selectedCustomer = customers.find(
      (c) => c.customerKey === selectedCustomerKey
    );
    if (!selectedCustomer) return;
    if (customerKey !== selectedCustomerKey) {
      setTaskHourlyRate(selectedCustomer.hourlyRate.toString());
    }
    setCustomerKey(selectedCustomerKey);
  };

  const allowSubmit = () => customerKey.length && taskName.length;

  const addNewTask = () =>
    saveTaskAction(
      customerKey,
      taskName,
      taskHourlyRate,
      taskDescription,
      taskUrl
    );

  return (
    <form className="">
      <TaskFormCustomerSelect
        customers={customers}
        updateCustomerKey={updateCustomerKey}
        selectedCustomerKey={customerKey}
      />
      <div className="form-group">
        <label htmlFor="customer-name">Task name</label>
        <input
          type="text"
          className="form-control"
          id="customer-name"
          onChange={updateTaskName}
          value={taskName}
          maxLength={128}
          required
        />
      </div>
      <TaskFormUrl
        url={taskUrl}
        setUrl={setTaskUrl}
        taskKey={defaultData?.taskKey}
      />

      <div className="form-group">
        <label htmlFor="customer-default-hourly-rate">Hourly rate</label>
        <input
          type="number"
          step="0.01"
          className="form-control"
          id="customer-default-hourly-rate"
          onChange={updateHourlyRate}
          value={taskHourlyRate}
        />
      </div>
      <div className="form-group">
        <label htmlFor="customer-description">Additional description</label>
        <textarea
          className="form-control"
          id="customer-description"
          rows={2}
          onChange={updateTaskDescription}
          value={taskDescription}
        ></textarea>
      </div>

      <div className="mt-3 d-flex">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={addNewTask}
          disabled={!allowSubmit()}
        >
          {saveDataTxt}
        </button>
        {cancelAction && (
          <button className="btn btn-link btn-sm ml-3" onClick={cancelAction}>
            Cancel
          </button>
        )}
        <div className="ml-auto">
          {removeTask && <TaskFormRemoveTask removeTask={removeTask} />}
        </div>
      </div>
    </form>
  );
};

export default TaskForm;
