import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { API_getTaskListByKeys } from "../../../../../services/tasks";
import { useAppSelector } from "../../../../../store";
import { Task } from "../../../../../store/data/types";
import { Report } from "../../../../../store/reports/types";
import Loader from "../../../../Loader";
import ManageExistingReportsItemDetailsLongText from "./ManageExistingReportsItemDetailsLongText";
import ManageExistingReportsItemDetailsReport from "./ManageExistingReportsItemDetailsReport";

interface IProps {
  report: Report;
  toggleEdit: () => void;
}

const ManageExistingReportsItemDetails = ({ report, toggleEdit }: IProps) => {
  const [reportTasksList, setReportTasksList] = useState<Task[]>([]);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState("");
  const { customers } = useAppSelector((app) => app.data);

  useEffect(() => {
    API_getTaskListByKeys(report.taskKeyList)
      .then((response) => {
        setError("");
        setLoader(false);

        setReportTasksList(response.data);
      })
      .catch((errorMessage) => {
        setError(errorMessage);
        setLoader(false);
      });
  }, [report.taskKeyList]);

  const customerDetail = customers.find(
    (c) => c.customerKey === report.customerKey
  );
  if (!customerDetail) return <div>Cannot find customer for this report</div>;

  return (
    <div>
      <h6>
        {report.title.length > 0 ? `${report.title}, ` : "Report "} generated on{" "}
        {report.generatedTime}
      </h6>
      <div className="small">
        Customer: <strong>{customerDetail.name}</strong>
      </div>
      {report.description.length > 0 && (
        <div className="small text-muted">
          Description:{" "}
          <strong>
            <ManageExistingReportsItemDetailsLongText
              text={report.description}
            />
          </strong>
        </div>
      )}
      {report.additionalComment.length > 0 && (
        <div className="small text-muted">
          Additional comment:{" "}
          <strong>
            <ManageExistingReportsItemDetailsLongText
              text={report.additionalComment}
            />
          </strong>
        </div>
      )}
      <div className="small text-muted">
        Include a list of task times:{" "}
        <strong>{report.includeTaskTimeList ? "Yes" : "No"}</strong>
      </div>

      <div className="d-flex justify-content-end">
        <button
          className="btn btn-sm text-muted font-weight-normal"
          onClick={toggleEdit}
        >
          <small>
            Edit this report <FontAwesomeIcon icon={faEdit} />
          </small>
        </button>
      </div>

      <div className="mt-3">
        {error.length > 0 && <div className="alert alert-danger">{error}</div>}
        {loader ? (
          <Loader />
        ) : (
          <ManageExistingReportsItemDetailsReport
            reportTasksList={reportTasksList}
          />
        )}
      </div>
    </div>
  );
};

export default ManageExistingReportsItemDetails;
