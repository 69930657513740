import React, { useState } from "react";
import { API_removeTaskTime } from "../../../../services/tasks";
import { useAppDispatch } from "../../../../store";
import { removeTaskTimes } from "../../../../store/data/actions";
import { TaskTime } from "../../../../store/data/types";
import Loader from "../../../Loader";

interface IProps {
  taskKey: string;
  taskTime: TaskTime;
  closeModal?: () => void;
}

const TaskTimeEditRemoveTaskTime = ({
  taskKey,
  taskTime: { taskTimeKey },
  closeModal,
}: IProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const toggleRemoveConfirmation = () =>
    setRemoveConfirmation(!removeConfirmation);

  const removeTask = () => {
    setLoading(true);
    API_removeTaskTime(taskTimeKey)
      .then((response) => {
        dispatch(removeTaskTimes([{ taskKey, taskTimeKey }]));
        setLoading(false);
        if (closeModal) closeModal();
      })
      .catch((e) => {
        setError(e);
        setTimeout(() => setError(""), 4000);
        setRemoveConfirmation(false);
        setLoading(false);
      });
  };

  if (loading)
    return (
      <div className="mx-2 d-flex align-items-center">
        <Loader small />
      </div>
    );
  if (error.length > 0)
    return (
      <div className="alert alert-danger p-1">
        <small>{error}</small>
      </div>
    );
  if (removeConfirmation)
    return (
      <div>
        <span className="mr-2">
          <small>Do you really want to remove this time?</small>
        </span>
        <button
          className="btn btn-sm btn-outline-dribbble py-1 px-1 mr-2"
          onClick={removeTask}
        >
          <small>Yes</small>
        </button>
        <button
          className="btn btn-sm btn-outline-info py-1 px-1"
          onClick={toggleRemoveConfirmation}
        >
          <small>Cancel</small>
        </button>
      </div>
    );
  return (
    <>
      <button
        className="btn btn-sm btn-outline-dribbble py-1 px-1"
        onClick={toggleRemoveConfirmation}
      >
        <small>Remove task</small>
      </button>
      <button
        className="btn btn-sm btn-outline-primary py-1 px-1 ml-2"
        onClick={closeModal}
      >
        <small>Close</small>
      </button>
    </>
  );
};

export default TaskTimeEditRemoveTaskTime;
