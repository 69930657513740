import { APP_DATA_LOADED, SET_MESSAGE } from "./types";

export const setAppDataLoaded = (loaded: boolean) => ({
  type: APP_DATA_LOADED,
  payload: loaded,
});

export const setAppMessage = (message: string) => ({
  type: SET_MESSAGE,
  payload: message,
});
