import React, { useMemo } from "react";
import { useState } from "react";
import { Customer } from "../../../../store/data/types";
import TaskFormCustomerSelectAddNewCustomer from "./TaskFormCustomerSelectAddNewCustomer";

interface IProps {
  customers: Customer[];
  selectedCustomerKey: string;
  updateCustomerKey: (customerKey: string) => void;
}

const TaskFormCustomerSelect = ({
  customers,
  selectedCustomerKey,
  updateCustomerKey,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const [addNewCustomerContainer, setAddNewCustomerContainer] = useState(false);
  const toggleAddNewCustomerContainer = () =>
    setAddNewCustomerContainer(!addNewCustomerContainer);

  const setCustomer = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const customerKey = e.currentTarget.dataset.customerkey;
    if (!customerKey) return;
    updateCustomerKey(customerKey);
    toggleOpen();
  };

  const selectedCustomer = useMemo(
    () => customers.find((c) => c.customerKey === selectedCustomerKey),
    [customers, selectedCustomerKey]
  );

  return (
    <div className="dropdown show">
      <button
        className="btn btn-block btn-outline-light btn-outline-light-select dropdown-toggle text-left"
        type="button"
        onClick={toggleOpen}
      >
        {selectedCustomer ? selectedCustomer.name : "Select customer"}
      </button>
      {isOpen && !addNewCustomerContainer && (
        <div className="dropdown-menu show w-100">
          {customers.map((customer) => (
            <button
              className="dropdown-item"
              key={`customer_${customer.customerKey}`}
              data-customerkey={customer.customerKey}
              onClick={setCustomer}
            >
              {customer.name}
            </button>
          ))}
          <button
            className="dropdown-item"
            onClick={toggleAddNewCustomerContainer}
          >
            Add new a customer
          </button>
        </div>
      )}
      {isOpen && addNewCustomerContainer && (
        <TaskFormCustomerSelectAddNewCustomer
          customers={customers}
          toggleAddNewCustomerContainer={toggleAddNewCustomerContainer}
          updateCustomerKey={updateCustomerKey}
        />
      )}
    </div>
  );
};

export default TaskFormCustomerSelect;
