export const APP_DATA_LOADED = "APP_DATA_LOADED";
export const SET_MESSAGE = "SET_MESSAGE";
export const LOGOUT = "LOGOUT";

export interface App {
  appDataLoaded: boolean;
  appMessage: string;
}

// actions
interface SetAppLoaded {
  type: typeof APP_DATA_LOADED;
  payload: boolean;
}
interface SetAppMessage {
  type: typeof SET_MESSAGE;
  payload: string;
}
interface Logout {
  type: typeof LOGOUT;
}

export type AppActionTypes = SetAppLoaded | SetAppMessage | Logout;
