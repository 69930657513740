import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../store";
import { TaskTime } from "../../../../store/data/types";

import { ModalProps } from "../../Common/Modal/ModalWindow";
import TaskActiveTimeListItem from "./TaskActiveTimeListItem";
import TaskActiveTimeListItemHeader from "./TaskActiveTimeListItemHeader";
import TaskDetailEdit from "./TaskDetailEdit";
import TaskDetailPreview from "./TaskDetailPreview";

interface IProps extends ModalProps {}

const TaskDetail = ({ setTitle, closeModal }: IProps) => {
  const params = useParams();
  const { tasks, customers } = useAppSelector((app) => app.data);
  const { taskUrl, customerUrl } = params;
  // 1st get project detail, then find task
  const taskCustomer = customers.find((c) => c.url === customerUrl);
  const taskDetail = tasks.find(
    (task) =>
      task.url.toString() === taskUrl &&
      task.customerKey === taskCustomer?.customerKey
  );
  const [edit, setEdit] = useState(false);
  const toggleEdit = () => setEdit(!edit);

  useEffect(() => {
    if (setTitle) setTitle(`Task detail ${taskDetail?.name}`);
  }, [setTitle, taskDetail?.name]);

  if (!taskDetail)
    return (
      <div className="alert alert-danger">
        Sorry, this task doesn't exist :(
      </div>
    );

  const renderTaskItem = (taskTime: TaskTime) => (
    <TaskActiveTimeListItem
      key={`task_time_item_${taskTime.taskTimeKey}`}
      task={taskDetail}
      taskTime={taskTime}
    />
  );

  // to be done
  if (edit)
    return (
      <TaskDetailEdit
        taskDetail={taskDetail}
        toggleEdit={toggleEdit}
        closeModal={closeModal}
      />
    );
  return (
    <>
      <TaskDetailPreview taskDetail={taskDetail} toggleEdit={toggleEdit} />
      {taskDetail.taskTimes.length ? (
        <>
          <h6>Task times list</h6>
          <table className="table table-responsive freelance-table">
            <TaskActiveTimeListItemHeader />
            <tbody>{taskDetail.taskTimes.map(renderTaskItem)}</tbody>
          </table>
        </>
      ) : (
        <div className="text-center">Task times have not been added yet</div>
      )}
    </>
  );
};

export default TaskDetail;
