import { DETAUL_EMPTY_USER_BILLING } from "../../Utils/constants";
import { LOGOUT } from "../app/types";
import { SET_USER, User, UserActionTypes } from "./types";

const initialState: User = {
  details: {
    userKey: "",
    userName: "",
    email: "",
    accountType: "standard",
  },
  billing: DETAUL_EMPTY_USER_BILLING,
};

export const userReducer = (
  state = initialState,
  action: UserActionTypes
): User => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
