import React, { useState } from "react";
import { useEffect } from "react";
import { Report } from "../../../../../store/reports/types";
import { ModalProps } from "../../../Common/Modal/ModalWindow";
import ManageExistingReportsItemDetails from "./ManageExistingReportsItemDetails";
import ManageExistingReportsItemEdit from "./ManageExistingReportsItemEdit";

interface IProps extends ModalProps {
  report: Report;
  updateReport: (updatedReport: Report) => void;
}

const ManageExistingReportsItemModal = ({
  report,
  updateReport,
  setTitle,
}: IProps) => {
  const [edit, setEdit] = useState(false);
  const toggleEdit = () => setEdit(!edit);

  useEffect(() => {
    if (setTitle) setTitle(`Manage report ${report.title ? report.title : ""}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return edit ? (
    <ManageExistingReportsItemEdit
      report={report}
      toggleEdit={toggleEdit}
      updateReport={updateReport}
    />
  ) : (
    <ManageExistingReportsItemDetails report={report} toggleEdit={toggleEdit} />
  );
};

export default ManageExistingReportsItemModal;
