import { LOGOUT } from "../app/types";
import { SET_USER, User } from "./types";

export const setUser = (user: User) => ({
  type: SET_USER,
  payload: user,
});

export const logOut = () => ({
  type: LOGOUT
})
