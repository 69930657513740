import axios from "axios";

import { API } from "../config/constants";

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    config.headers = config.headers ?? {};
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    if (response.data.token) {
      localStorage.setItem("token", response.data.token);
    }
    if (response.data.refresh_token) {
      localStorage.setItem("refresh-token", response.data.refresh_token);
    }
    return response;
  },
  function (error) {
    const errorMessage =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : "An unexpected error has occurred";
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refresh-token");
      return axios
        .post(`${API}/signform`, {
          action: "refreshToken",
          refresh_token: refreshToken,
        })
        .then((res) => {
          if (res.status === 201) {
            localStorage.setItem("token", res.data);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + localStorage.getItem("token");
            return axios(originalRequest);
          }
        });
    }

    return Promise.reject(errorMessage);
  }
);
