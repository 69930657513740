import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_editTask, API_removeTask } from "../../../../services/tasks";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { editTask, removeTasksByKey } from "../../../../store/data/actions";
import { Task } from "../../../../store/data/types";
import Loader from "../../../Loader";
import TaskForm from "./TaskForm";

interface IProps {
  taskDetail: Task;
  toggleEdit: () => void;
  closeModal?: () => void;
}

const TaskDetailEdit = ({ taskDetail, toggleEdit, closeModal }: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { customers } = useAppSelector((app) => app.data);
  const customer = customers.find(
    (c) => c.customerKey === taskDetail.customerKey
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const editTaskAction = (
    customerKey: string,
    name: string,
    hourlyRate: string,
    description: string,
    taskUrl: string
  ) => {
    setLoading(true);
    API_editTask(
      taskDetail.taskKey,
      name,
      description,
      hourlyRate,
      customerKey,
      taskUrl
    )
      .then((response) => {
        // setAddNew(false);
        dispatch(editTask(response.data));
        if (response.data.url !== taskDetail.url) {
          navigate(`/app/C-${customer?.url}/${response.data.url}`, { replace: true });
        }

        toggleEdit();
      })
      .catch((e) => {
        setError(e);
        setTimeout(() => {
          setError("");
        }, 4000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeTask = () => {
    setLoading(true);
    API_removeTask(taskDetail.taskKey, customer!.customerKey)
      .then(() => {
        dispatch(removeTasksByKey([taskDetail.taskKey]));
        toggleEdit();
        setLoading(false);
        if (closeModal) closeModal();
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
        setTimeout(() => {
          setError("");
        }, 4000);
      });
  };

  const removeTaskAction =
    taskDetail.taskTimes.length === 0 ? removeTask : undefined;
  if (!customer) return null;
  if (loading) return <Loader />;
  return (
    <>
      {error.length > 0 && <div className="alert alert-danger">{error}</div>}
      <TaskForm
        defaultData={{
          taskName: taskDetail.name,
          taskHourlyRate: taskDetail.hourlyRate.toString(),
          taskDescription: taskDetail.description,
          taskUrl: taskDetail.url,
          customerKey: taskDetail.customerKey,
          taskKey: taskDetail.taskKey,
        }}
        saveTaskAction={editTaskAction}
        saveDataTxt="Edit Task"
        cancelAction={toggleEdit}
        removeTask={removeTaskAction}
      />
      {taskDetail.taskTimes.length > 0 && (
        <div className="small text-muted ml-auto">
          You cannot delete this task if it contains any times. To delete this
          task, delete times first.
        </div>
      )}
    </>
  );
};

export default TaskDetailEdit;
