import React from "react";
import AccountUpgradeSelect from "./AccountUpgradeSelect";

const AccountUpgrade = () => {
  // const [selectedPackage, setSelectedPackage] = useState<AccountType | undefined>(
  //   undefined
  // );

  // return selectedPackage ? (
  //   <AccountUpgradePayment selectedPackage={selectedPackage} />
  // ) : (
  //   <AccountUpgradeSelect setSelectedPackage={setSelectedPackage} />
  // );
  return <AccountUpgradeSelect />;
};

export default AccountUpgrade;
