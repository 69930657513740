const Footer = () => (
  <footer className="footer section py-3 mt-auto">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-6">
        </div>

        <div className="col-12 col-lg-6 mb-4 mb-lg-0">
          <p className="mb-0 text-right small text-muted">
            Copyright © Workstmt.com
          </p>
        </div>
        
      </div>
    </div>
  </footer>
);

export default Footer;
