import React, { Component } from "react";

interface IState {
  hasError: boolean;
}
interface IProps {
  children: JSX.Element;
}
class AppError extends Component<IProps, IState> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error: any) {
    console.log("GOT ERROR:", error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  //   // logErrorToMyService(error, errorInfo);
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>An unexpected error</h1>;
    }

    return this.props.children;
  }
}

export default AppError;
