import React, { useState } from "react";
import { API_editTaskTime } from "../../../../services/tasks";
import { useAppDispatch } from "../../../../store";
import { editTaskTime } from "../../../../store/data/actions";
import { TaskTime } from "../../../../store/data/types";
import Loader from "../../../Loader";

interface IProps {
  taskKey: string;
  taskTime: TaskTime;
  closeModal?: () => void;
}
const TaskTimeEditResumeTime = ({ taskKey, taskTime, closeModal }: IProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const toggleRemoveConfirmation = () =>
    setRemoveConfirmation(!removeConfirmation);

  const resumeTask = () => {
    setLoading(true);
    API_editTaskTime(taskTime.taskTimeKey, taskTime.start, null)
      .then((response) => {
        dispatch(editTaskTime(taskKey, response.data));
        if (closeModal) closeModal();
      })
      .catch((e) => {
        setError(e);
        setTimeout(() => setError(""), 4000);
      })
      .finally(() => setLoading(false));
  };

  if (taskTime.stop === null) return null;
  if (loading)
    return (
      <div className="mx-2 d-flex align-items-center">
        <Loader small />
      </div>
    );
  if (error.length > 0)
    return (
      <div className="alert alert-danger p-1">
        <small>{error}</small>
      </div>
    );
  if (removeConfirmation)
    return (
      <div className="mr-2">
        <span className="mr-2">
          <small>Do you really want to resume this time?</small>
        </span>
        <button
          className="btn btn-sm btn-outline-dribbble py-1 px-1 mr-2"
          onClick={resumeTask}
        >
          <small>Yes</small>
        </button>
        <button
          className="btn btn-sm btn-outline-info py-1 px-1"
          onClick={toggleRemoveConfirmation}
        >
          <small>Cancel</small>
        </button>
      </div>
    );
  return (
    <button
      className="btn btn-sm btn-outline-light py-1 px-1 mr-2"
      onClick={toggleRemoveConfirmation}
    >
      <small>Resume time</small>
    </button>
  );
};

export default TaskTimeEditResumeTime;
