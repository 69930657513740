import React, { useState } from "react";
import { useAppSelector } from "../../../../store";
import { Task } from "../../../../store/data/types";
import { getCustomerName } from "../../../../Utils";
import TaskActiveTimeList from "./TaskActiveTimeList";
import TaskListFilterResultsBox from "./TaskListFilterResultsBox";
import TaskListHeader from "./TaskListHeader";
import TaskListItem from "./TaskListItem";

interface IProps {
  tasksForCustomerKey?: string;
  displayCustomerName?: boolean;
}

const TaskList = ({ tasksForCustomerKey, displayCustomerName }: IProps) => {
  const [searchText, setSearchText] = useState("");
  const { tasks, customers } = useAppSelector((app) => app.data);
  const taskList = tasksForCustomerKey
    ? tasks.filter((task) => task.customerKey === tasksForCustomerKey)
    : tasks;

  const applySearchFilter = (task: Task) => {
    if (!searchText.length) return true;
    const searchLowerCase = searchText.toLocaleLowerCase();
    const customerName = getCustomerName(customers, task.customerKey, true);
    return (
      customerName?.toLocaleLowerCase().includes(searchLowerCase) ||
      task.name.toLocaleLowerCase().includes(searchLowerCase)
    );
  };

  const taskTable = () => (
    <div className="overview-task-list">
      <table className="table freelance-table table-responsive">
        <TaskListHeader />
        <tbody>{taskList.filter(applySearchFilter).map(renderTask)}</tbody>
      </table>
    </div>
  );

  const renderTask = (task: Task) => (
    <React.Fragment key={`task_item_${task.taskKey}`}>
      <TaskListItem task={task} displayCustomerName={displayCustomerName} />
      <TaskActiveTimeList task={task} />
    </React.Fragment>
  );

  return (
    <div className="list-group">
      <div className="d-flex align-items-start align-items-md-center mb-1 flex-column flex-md-row">
        <div className="d-flex align-items-center">
          <h6 className="text-nowrap mr-3 mb-0">Task List</h6>
          <TaskListFilterResultsBox
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </div>
        <div className="font-small text-muted ml-auto">
          Total time spent and total earnings are based on{" "}
          <u> completed tasks</u>, not ongoing tasks.
        </div>
      </div>
      {taskList.length ? (
        taskTable()
      ) : (
        <div className="mb-3 small">Please add first task</div>
      )}
    </div>
  );
};

export default TaskList;
