import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../store";
import { ModalProps } from "../../Common/Modal/ModalWindow";
import TaskList from "../Task/TaskList";
import CustomerDetailData from "./CustomerDetailData";

interface IProps extends ModalProps {}

const CustomerDetail = ({ setTitle, closeModal }: IProps) => {
  const params = useParams();
  const { customers, tasks } = useAppSelector((app) => app.data);

  const { customerUrl } = params;
  const customerDetail = customers.find(
    (customer) => customer.url === customerUrl
  );
  const customerTasks = tasks.filter(
    (task) => task.customerKey === customerDetail?.customerKey
  );

  useEffect(() => {
    if (setTitle) setTitle(`Customer detail ${customerDetail?.name}`);
  }, [customerDetail?.name, setTitle]);

  if (!customerDetail)
    return (
      <div className="alert alert-danger">
        Sorry, this customer doesn't exist :(
      </div>
    );

  return (
    <div className="card bg-white border-light mb-3">
      <div className="card-body p-4">
        <CustomerDetailData
          customerDetail={customerDetail}
          closeModal={closeModal}
        />
        {customerTasks.length ? (
          <TaskList tasksForCustomerKey={customerDetail.customerKey} />
        ) : (
          <div className="text-center">No tasks added yet</div>
        )}
      </div>
    </div>
  );
};

export default CustomerDetail;
