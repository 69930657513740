import React, { useState } from "react";
import ForgotPasswordFormBase from "./ForgotPasswordFormBase";
import ForgotPasswordFormNewPass from "./ForgotPasswordFormNewPass";

const ForgotPasswordForm = () => {
  const [usernameForPasswordChange, setupUsernameForPasswordChange] = useState(
    ""
  );

  if (!usernameForPasswordChange.length)
    return (
      <ForgotPasswordFormBase
        setupUsernameForPasswordChange={setupUsernameForPasswordChange}
      />
    );

  return (
    <ForgotPasswordFormNewPass
      usernameForPasswordChange={usernameForPasswordChange}
    />
  );
};

export default ForgotPasswordForm;
