import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../store";
import { Customer } from "../../../../store/data/types";
import { formatter } from "../../../../Utils";

const CustomerList = () => {
  const { customers, tasks } = useAppSelector((app) => app.data);

  const customerTable = () => (
    <table className="table table-responsive freelance-table">
      <thead className="bg-200 text-800">
        <tr>
          <th className="text-nowrap">Customer</th>
          <th className="text-nowrap text-center">Default hourly rate</th>
          <th className="text-nowrap text-center">Tasks</th>
        </tr>
      </thead>
      <tbody>{customers.map(renderCustomer)}</tbody>
    </table>
  );

  const renderCustomer = (customer: Customer) => {
    const customerTasks = tasks.filter(
      (task) => task.customerKey === customer.customerKey
    );
    return (
      <tr key={`customer_summary_${customer.customerKey}`}>
        <td>
          <Link
            className="cut-text"
            to={`/app/C-${customer.url}`}
          >
            {customer.name}
          </Link>
          <div className="small text-muted cut-text">
            {customer.description}
          </div>
        </td>
        <td className="text-center">{formatter.format(customer.hourlyRate)}</td>
        <td className="text-center">{customerTasks.length}</td>
      </tr>
    );
  };
  return (
    <div className="list-group">
      <h6>Customer List</h6>

      {customers.length ? (
        customerTable()
      ) : (
        <div>Please add first customer</div>
      )}
    </div>
  );
};

export default CustomerList;
