import React, { useState } from "react";
import { Customer } from "../../../../store/data/types";
import CustomerForm from "./CustomerForm";
import Loader from "../../../Loader";
import { API_archiveCustomer, API_editCustomer } from "../../../../services/customers";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { editCustomer, RemoveCustomerByKey } from "../../../../store/data/actions";
import { useNavigate } from "react-router-dom";

interface IProps {
  customerDetail: Customer;
  toggleEdit: () => void;
  closeModal?: () => void;
}

const CustomerDetailEdit = ({ customerDetail, toggleEdit, closeModal }: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { tasks } = useAppSelector((app) => app.data);
  const customerTasks = tasks.filter(
    (t) => t.customerKey === customerDetail.customerKey
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const editCustomerAction = (
    name: string,
    description: string,
    hourlyRate: string,
    customerUrl: string
  ) => {
    setLoading(true);

    API_editCustomer(
      customerDetail.customerKey,
      name,
      description,
      hourlyRate,
      customerUrl
    )
      .then((response) => {
        // setAddNew(false);
        dispatch(editCustomer(response.data));
        if (response.data.url !== customerDetail.url) {
          navigate(`/app/C-${response.data.url}`, { replace: true });
        }
        toggleEdit();
      })
      .catch((e) => {
        setError(e);
        setTimeout(() => {
          setError("");
        }, 4000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeCustomerAction = () => {
    setLoading(true);
    API_archiveCustomer(customerDetail.customerKey)
      .then((response) => {
        dispatch(RemoveCustomerByKey(response.data.customerKey));
        toggleEdit();
        setLoading(false);
        if (closeModal) closeModal();
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
        setTimeout(() => {
          setError("");
        }, 4000);
      });
  };
  const removeCustomer =
    customerTasks.length === 0 ? removeCustomerAction : undefined;

  if (loading) return <Loader />;
  return (
    <div className="mb-4">
      {error.length > 0 && <div className="alert alert-danger">{error}</div>}
      <CustomerForm
        defaultData={{
          customerName: customerDetail.name,
          customerUrl: customerDetail.url,
          customerDescription: customerDetail.description,
          hourlyRate: customerDetail.hourlyRate.toString(),
          customerKey: customerDetail.customerKey,
        }}
        saveDataAction={editCustomerAction}
        saveDataTxt="Edit customer"
        cancelAction={toggleEdit}
        removeCustomer={removeCustomer}
      />
      {customerTasks.length > 0 && (
        <div className="small text-muted ml-auto">
          You cannot delete this client if it contains a task. To remove this
          client, create a report on all tasks belonging to it.
        </div>
      )}
    </div>
  );
};

export default CustomerDetailEdit;
