import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut } from "../../store/user/actions";
import React from "react";

const SignOut = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh-token");
    dispatch(logOut())
  }, [dispatch]);

  return <Navigate replace to="/" />;
};

export default SignOut;
