import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Task } from "../../../../../store/data/types";
import { formatter, getEarnings } from "../../../../../Utils";
import TaskActiveTimeList from "../../Task/TaskActiveTimeList";

interface IProps {
  task: Task;
}

const ManageExistingReportsItemDetailsReportTaskItem = ({ task }: IProps) => {
  const [showMore, setShowMore] = useState(false);
  const toggleMore = () => setShowMore(!showMore);

  return (
    <>
      <tr>
        <td>
          <button className="btn btn-link text-black px-2 py-0" onClick={toggleMore}>
            <FontAwesomeIcon
              icon={showMore ? faArrowUp : faArrowDown}
              className="mr-2"
            />
            <small>{task.name}</small>
          </button>
        </td>
        <td>
          <small>{getEarnings(task.taskTimes, task.hourlyRate)}</small>
        </td>
      </tr>
      {showMore && (
        <tr>
          <td colSpan={2}>
            <table
              className="table table-responsive small"
              key={`report_task_item_${task.taskKey}`}
            >
              <tbody>
                <tr>
                  <td>Name:</td>
                  <td>{task.name}</td>
                </tr>
                <tr>
                  <td>Description:</td>
                  <td>{task.description}</td>
                </tr>
                <tr>
                  <td>Created:</td>
                  <td>{task.created}</td>
                </tr>
                <tr>
                  <td>Hourly rate:</td>
                  <td>{formatter.format(task.hourlyRate)}</td>
                </tr>
                <tr>
                  <td>Earnings:</td>
                  <td>{getEarnings(task.taskTimes, task.hourlyRate)}</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <table>
                      <tbody>
                        <TaskActiveTimeList
                          task={task}
                          showAllTasksForReport={true}
                        />
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

export default ManageExistingReportsItemDetailsReportTaskItem;
