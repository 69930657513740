import {
  faCalendarAlt,
  faChartPie,
  faRocket,
  faSignOutAlt,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../store";
import { capitalizeFirst } from "../../../Utils";

interface IProps {
  mobileMenuVisible: boolean;
  toggleMobileMenuVisible: () => void;
  closeMobileMenuVisible: () => void;
}

const SidebarMenu = ({
  mobileMenuVisible,
  toggleMobileMenuVisible,
  closeMobileMenuVisible,
}: IProps) => {
  const { details } = useSelector((app: RootState) => app.user);
  const closeMobileMenu = () => closeMobileMenuVisible();
  return (
    <nav
      id="sidebarMenu"
      className={`sidebar d-md-block collapse pl-4 pr-3 ${
        mobileMenuVisible ? "show" : ""
      }`}
    >
      <div className="sidebar-sticky pt-4 mx-auto">
        <div className="user-card d-flex align-items-center justify-content-start  pb-2">
          <div className="d-flex align-items-center">
            <div className="d-block">
              <h2 className="h6">Hi, {details.userName}</h2>
              <div className="text-muted small">
                Account type: {capitalizeFirst(details.accountType)}
              </div>
            </div>
          </div>
          <button
            onClick={toggleMobileMenuVisible}
            className="collapse-close d-md-none btn ml-auto"
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <ul className="nav flex-column mt-4">
          <li className="nav-item ">
            <Link to="/app" className="nav-link" onClick={closeMobileMenu}>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={faChartPie} />
              </span>
              <span>Dashboard</span>
            </Link>
          </li>
          <li className="nav-item ">
            <Link
              to="/app/reports"
              className="nav-link"
              onClick={closeMobileMenu}
            >
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={faChartPie} />
              </span>
              <span>Reports</span>
            </Link>
          </li>
          <li className="nav-item ">
            <Link
              to="/app/calendar"
              className="nav-link"
              onClick={closeMobileMenu}
            >
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={faCalendarAlt} />
              </span>
              <span>Calendar</span>
            </Link>
          </li>
          <li className="nav-item ">
            <Link
              to="/app/statistics"
              className="nav-link"
              onClick={closeMobileMenu}
            >
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={faCalendarAlt} />
              </span>
              <span>Statistics</span>
            </Link>
          </li>
          <li className="nav-item ">
            <Link
              to="/app/account"
              className="nav-link"
              onClick={closeMobileMenu}
            >
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={faUser} />
              </span>
              <span>Account</span>
            </Link>
          </li>
          {details.accountType === "standard" && (
            <li className="nav-item ">
              <Link
                to="/app/account/upgrade"
                className="nav-link"
                onClick={closeMobileMenu}
              >
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={faRocket} className="text-warning" />
                </span>
                <span>Upgrade account</span>
              </Link>
            </li>
          )}
          <li
            role="separator"
            className="dropdown-divider mt-4 mb-3 border-blue"
          ></li>

          <li className="nav-item">
            <Link
              className="nav-link d-flex align-items-center"
              to="/login/signout"
              onClick={closeMobileMenu}
            >
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={faSignOutAlt} />
              </span>
              <span>Sign Out</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default SidebarMenu;
