import { Children, cloneElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { createPortal } from "react-dom";
import "./ModalWindow.css";
import React from "react";

interface IProps {
  children: JSX.Element;
  closeModalCustomAction?: () => void;
}

// interface LocationState {
//   backgroundLocation?: {
//     pathname: string;
//   };
// }

export interface ModalProps {
  closeModal?: () => void;
  setTitle?: (title: string) => void;
}

const portalElement = document.getElementById("modal");

const ModalWindow = ({ children, closeModalCustomAction }: IProps) => {
  const navigate = useNavigate();
  const [modalTitle, setTitle] = useState("");

  const closeModal = () => {
    closeModalCustomAction ? closeModalCustomAction() : navigate("/app");
  };
  const handleKeydown = (e: KeyboardEvent) => {
    if (e.key === "Escape") closeModal();
  };

  useEffect(() => {
    document.body.classList.add("modal-open");
    window.addEventListener("keydown", handleKeydown);
    return () => {
      document.body.classList.remove("modal-open");
      window.removeEventListener("keydown", handleKeydown);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const injectedModalProps: ModalProps = {
    closeModal,
    setTitle,
  };

  const modalContent = (
    <>
      <div className="modal fade show" style={{ display: "block" }}>
        <div className="modal-dialog modal-xl">
          <div className="modal-content px-2 pb-3">
            <div className="modal-title">
              {modalTitle}
              <button
                type="button"
                onClick={closeModal}
                className="close-modal btn"
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
            {Children.map(children, (child) =>
              cloneElement(child, { ...injectedModalProps })
            )}
          </div>
        </div>
        <div
          role="button"
          className="modal-backdrop fade show"
          onClick={closeModal}
          onKeyDown={closeModal}
        ></div>
      </div>
    </>
  );

  if (!portalElement) return null;

  return createPortal(modalContent, portalElement);
};

export default ModalWindow;
