import { faUnlockAlt, faEye, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { API_passwordRemindUpdate } from "../../services/auth";
import Loader from "../Loader";

interface IProps {
  usernameForPasswordChange: string;
}

const ForgotPasswordFormNewPass = ({ usernameForPasswordChange }: IProps) => {
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [loader, setLoader] = useState(false);
  const [paddwordUpdated, setPasswordUpdated] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const confirmNewPassword = () => {
    setLoader(true);
    API_passwordRemindUpdate(usernameForPasswordChange, code, password)
      .then(() => setPasswordUpdated(true))
      .catch(setError)
      .finally(() => setLoader(false));
  };
  const setupPassword = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);
  const setupCode = (event: React.ChangeEvent<HTMLInputElement>) =>
    setCode(event.target.value);

  const allowSubmit = () => {
    return password.length >= 6 && code.length === 5;
  };

  if (loader) return <Loader />;

  if (paddwordUpdated)
    return (
      <section className="vh-100 bg-soft d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center form-bg-image">
            <div className="col-12 d-flex align-items-center justify-content-center signup-container">
              <div className="signin-inner mt-3 mt-lg-0 bg-white shadow-soft border border-light rounded p-4 p-lg-5 w-100 fmxw-500">
                <div className="d-block d-sm-flex justify-content-center align-items-center mt-4">
                  <span className="font-weight-normal text-center">
                    Hasło zostało pomyślnie zmienione.
                    <br />
                    <Link to="/login">Możesz się teraz zalogować</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );

  return (
    <section className="vh-100 bg-soft d-flex align-items-center">
      <div className="container">
        <div className="row justify-content-center form-bg-image">
          <div className="col-12 d-flex align-items-center justify-content-center signup-container">
            <div className="signin-inner mt-3 mt-lg-0 bg-white shadow-soft border border-light rounded p-4 p-lg-5 w-100 fmxw-500">
              <div className="text-center text-md-center mb-4 mt-md-0">
                <h1 className="mb-3 h3">Password change</h1>
                <p className="text-gray">
                  Changing the password for the address{" "}
                  {usernameForPasswordChange}
                </p>
              </div>
              {error.length > 0 && (
                <div className="alert alert-danger">{error}</div>
              )}
              <form className="mt-5">
                <div className="form-group">
                  <small className="form-text text-muted">
                    New password, minimum 6 characters
                  </small>

                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </span>
                    </div>
                    <input
                      className="form-control"
                      placeholder="Password"
                      type={passwordShown ? "text" : "password"}
                      required
                      minLength={6}
                      onChange={setupPassword}
                      value={password}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <FontAwesomeIcon
                          onClick={togglePasswordVisiblity}
                          icon={faEye}
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <small className="form-text text-muted">
                    5-character code received by e-mail
                  </small>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Code from email"
                      required
                      onChange={setupCode}
                      value={code}
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <button
                    type="submit"
                    className="btn btn-block btn-primary"
                    onClick={confirmNewPassword}
                    disabled={!allowSubmit()}
                  >
                    Remin password
                  </button>
                </div>
              </form>
              <div className="d-block d-sm-flex justify-content-center align-items-center mt-4">
                <span className="font-weight-normal">
                  <Link to="/login/signin" className="small">
                    Go back
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordFormNewPass;
