import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import App from "../Components/App";
import ApplicationLoader from "../Components/App/Components/ApplicationLoader";
import TermsAndConditions from "../Components/Other/TermsAndConditons";
import SignForms from "../Components/SignForms";
import PrivateRoute from "./PrivateRoute";

const AppRouter = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route path="/error" element={<div>An unexpected error :(</div>} />
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/login/*" element={<SignForms />} />
      <Route path="/signin" element={<Navigate replace to="/login/signin" />} />
      <Route path="/signup" element={<Navigate replace to="/login/signup" />} />
      <Route
        path="/app/*"
        element={
          <PrivateRoute>
            <ApplicationLoader>
              <App />
            </ApplicationLoader>
          </PrivateRoute>
        }
      />
    </Routes>
  </Router>
);

// const AppRouter = () => (
//   <Router>
//     <Switch>
//       <Route exact path="/" render={() => <Redirect to="/login" />} />
//       <Route
//         exact
//         path="/error"
//         component={() => <div>An unexpected error :(</div>}
//       />
//       <Route exact path="/terms">
//         <TermsAndConditions />
//       </Route>
//       <Route
//         path="/login"
//         render={() => (
//           <>
//             <SignForms />
//           </>
//         )}
//       />
//       <PrivateRoute>
//         <ApplicationLoader>
//           <App />
//         </ApplicationLoader>
//       </PrivateRoute>
//     </Switch>
//   </Router>
// );

export default AppRouter;
