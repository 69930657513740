import { useRef, useState } from "react";
import { API_editTaskTime } from "../../../../services/tasks";
import { useAppDispatch } from "../../../../store";
import { TaskTime } from "../../../../store/data/types";
import { editTaskTime } from "../../../../store/data/actions";
import Loader from "../../../Loader";
import React from "react";

interface IProps {
  taskTime: TaskTime;
  taskKey: string;
}

const TaskTimeEditDescription = ({ taskTime, taskKey }: IProps) => {
  const dispatch = useAppDispatch();
  let timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showSavedMessage, setShowSavedMessage] = useState(false);

  const updateDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      saveDescription(value);
    }, 400);
  };

  const saveDescription = (updatedDescription: string) => {
    setLoading(true);
    API_editTaskTime(
      taskTime.taskTimeKey,
      taskTime.start,
      taskTime.stop,
      updatedDescription
    )
      .then((response) => {
        dispatch(editTaskTime(taskKey, response.data));
        setShowSavedMessage(true);
        setTimeout(() => setShowSavedMessage(false), 1600);
      })
      .catch((e) => {
        setError(e);
        setTimeout(() => setError(""), 4000);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      {error.length > 0 && (
        <div className="alert alert-danger p-1">
          <small>{error}</small>
        </div>
      )}
      <textarea
        className="form-control"
        rows={3}
        defaultValue={taskTime.description || ""}
        onChange={updateDescription}
      ></textarea>
      <div className="small d-flex justify-content-end align-items-center text-muted save-task-time-description-feedback">
        {showSavedMessage && <span>Description saved</span>}
        {loading && <><Loader small /> <span className="ml-2">saving...</span></>}
      </div>
    </div>
  );
};

export default TaskTimeEditDescription;
