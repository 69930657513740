import { UserBilling } from "../store/user/types";

const DASHBOARD_DEFAULT_TASK_TIME_ITEMS = 4;
const APP_URL = "https://workstmt.com/";
const APP_CUSTOMER_URL = "https://reports.workstmt.com/";

const DETAUL_EMPTY_USER_BILLING: UserBilling = {
  companyName: "",
  companyAddress: "",
  companyAddress2nd: "",
  companyCity: "",
  companyZip: "",
  companyState: "",
  companyCountryCode: "",
  taxId: "",
  stripeId: "",
};

export {
  DASHBOARD_DEFAULT_TASK_TIME_ITEMS,
  APP_URL,
  APP_CUSTOMER_URL,
  DETAUL_EMPTY_USER_BILLING,
};
