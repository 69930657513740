import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import SignOut from "./SignOut";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { API_userInfo } from "../../services/user";
import { setUser } from "../../store/user/actions";
import React from "react";

interface LocationState {
  state: {
    pathname?: string;
  };
}

const SignForms = () => {
  const [pathname, setPathname] = useState<string | null>(null);
  let { state } = useLocation() as LocationState;
  const { userKey } = useSelector((app: RootState) => app.user.details);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state?.pathname) setPathname(state.pathname);
  }, [state]);

  useEffect(() => {
    const jwt = localStorage.getItem("token");
    if (jwt && !userKey) {
      // try to signin using token
      API_userInfo().then((response) => dispatch(setUser(response.data)));
    }
  }, [userKey, dispatch]);

  const urlToRedirect = pathname || "/app";
  return (
    <Routes>
      <Route path="/signout" element={<SignOut />} />
      <Route path="/" element={<Navigate replace to="/login/signin" />} />
      <Route
        path="*"
        element={
          userKey ? (
            <Navigate replace to={urlToRedirect} />
          ) : (
            <Routes>
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/forgot-password" element={<ForgotPasswordForm />} />
            </Routes>
          )
        }
      />
    </Routes>
  );
};

export default SignForms;
