import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Report } from "../../../../../store/reports/types";
import { ModalWindow } from "../../../Common";
import TasksNoReportsAssignToExistingReportModal from "./TasksNoReportsAssignToExistingReportModal";

interface IProps {
  selectedTasksKeys: string[];
  customerReports: Report[];
  updateReport: (updatedReport: Report) => void;
}

const TasksNoReportsAssignToExistingReportBtn = ({
  selectedTasksKeys,
  customerReports,
  updateReport,
}: IProps) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  if (!customerReports.length) return null;
  return (
    <>
      <button
        className={`ml-2 btn btn-sm btn-light ${
          selectedTasksKeys.length ? "" : "disabled"
        }`}
        onClick={toggleOpen}
      >
        Assign selected task to existing report{" "}
        <FontAwesomeIcon icon={faFileAlt} />
      </button>

      {open && (
        <ModalWindow closeModalCustomAction={toggleOpen}>
          <TasksNoReportsAssignToExistingReportModal
            toggleOpen={toggleOpen}
            selectedTasksKeys={selectedTasksKeys}
            updateReport={updateReport}
            customerReports={customerReports}
          />
        </ModalWindow>
      )}
    </>
  );
};

export default TasksNoReportsAssignToExistingReportBtn;
