import React, { useState } from "react";
import {
  faChevronDown,
  faChevronUp,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Customer } from "../../../../store/data/types";
import { formatter } from "../../../../Utils";

interface IProps {
  customerDetail: Customer;
  toggleEdit: () => void;
}

const CustomerDetailPreview = ({ customerDetail, toggleEdit }: IProps) => {
  const [detailedView, setDetailedView] = useState(false);
  const toggleDetailedView = () => setDetailedView(!detailedView);

  const customerDetails = () => (
    <>
      <table className="table table-responsive freelancer-table small mb-0">
        <tbody>
          <tr>
            <td className="">Name:</td>
            <td>{customerDetail.name}</td>
          </tr>
          <tr>
            <td>Description:</td>
            <td>{customerDetail.description}</td>
          </tr>
          <tr>
            <td>Hourly rate:</td>
            <td>{formatter.format(customerDetail.hourlyRate)}</td>
          </tr>
        </tbody>
      </table>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-sm text-muted font-weight-normal"
          onClick={toggleEdit}
        >
          <small>
            Edit this customer <FontAwesomeIcon icon={faEdit} />
          </small>
        </button>
      </div>
    </>
  );
  return (
    <div>
      {customerDetail.name}
      <button className="btn bt-sm">
        <FontAwesomeIcon
          icon={detailedView ? faChevronUp : faChevronDown}
          onClick={toggleDetailedView}
        />
      </button>
      <hr className="mt-0 mb-1" />
      {detailedView && customerDetails()}
    </div>
  );
};

export default CustomerDetailPreview;
