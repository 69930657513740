import React, { useState } from "react";
import { emailIsValid } from "../../Utils";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/user/actions";
import { API_signIn } from "../../services/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faUnlockAlt, faUser } from "@fortawesome/free-solid-svg-icons";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const allowSubmit = () => {
    return password.length >= 6 && emailIsValid(username);
  };

  const signIn = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setLoader(true);
    API_signIn(username, password)
      .then((response) => {
        setLoader(false);
        dispatch(setUser(response.data.user));
        navigate("/app");
      })
      .catch((errorMessage) => {
        setLoader(false);
        setError(errorMessage);
      });
  };

  const setupPassword = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);
  const setupUsername = (event: React.ChangeEvent<HTMLInputElement>) =>
    setUsername(event.target.value);

  if (loader) return <Loader />;
  return (
    <section className="vh-100 bg-soft d-flex align-items-center">
      <div className="container">
        <div
          className="row justify-content-center form-bg-image"
          data-background="../assets/img/illustrations/signin.svg"
        >
          <div className="col-12 d-flex align-items-center justify-content-center signup-container">
            <div className="signin-inner mt-3 mt-lg-0 bg-white shadow-soft border border-light rounded p-4 p-lg-5 w-100 fmxw-500">
              <div className="text-center text-md-center mb-4 mt-md-0">
                <h1 className="mb-3 h3">Sign in to our platform</h1>
                <p className="text-gray">
                  Use your credentials to access your account.
                </p>
              </div>
              {error.length > 0 && (
                <div className="alert alert-danger">{error}</div>
              )}
              <form className="mt-5">
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                      required
                      onChange={setupUsername}
                      value={username}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </span>
                    </div>
                    <input
                      className="form-control"
                      placeholder="Password"
                      type={passwordShown ? "text" : "password"}
                      required
                      minLength={6}
                      onChange={setupPassword}
                      value={password}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <FontAwesomeIcon
                          onClick={togglePasswordVisiblity}
                          icon={faEye}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="d-block d-sm-flex justify-content-between align-items-center mt-2">
                    <div className="form-group form-check mt-3"></div>
                    <div>
                      <Link
                        to="/login/forgot-password"
                        className="small text-right"
                      >
                        Lost password?
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <button
                    type="submit"
                    className="btn btn-block btn-primary"
                    onClick={signIn}
                    disabled={!allowSubmit()}
                  >
                    Sign in
                  </button>
                </div>
              </form>
              <div className="d-block d-sm-flex justify-content-center align-items-center mt-4">
                <span className="font-weight-normal">
                  Not registered?{" "}
                  <Link to="/login/signup" className="font-weight-bold">
                    Create account
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
