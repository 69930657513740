import { App, AppActionTypes, APP_DATA_LOADED, SET_MESSAGE, LOGOUT } from "./types";

const initialState: App = {
  appDataLoaded: false,
  appMessage: "",
};

export const appReducer = (
  state = initialState,
  action: AppActionTypes
): App => {
  switch (action.type) {
    case APP_DATA_LOADED:
      return {
        ...state,
        appDataLoaded: action.payload,
      };
    case SET_MESSAGE:
      return { ...state, appMessage: action.payload };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
