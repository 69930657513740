import React from "react";
import { useAppSelector } from "../../../../store";
import { Task } from "../../../../store/data/types";
import { Link } from "react-router-dom";
import {
  formatter,
  formatTimeSpent,
  getCustomerName,
  getEarnings,
} from "../../../../Utils";
import TaskListItemAddTime from "./TaskListItemAddTime";

interface IProps {
  task: Task;
  hideTaskControls?: boolean;
  displayCustomerName?: boolean;
  noTrItem?: boolean;
}

const TaskListItem = ({
  task,
  hideTaskControls,
  displayCustomerName,
  noTrItem,
}: IProps) => {
  const { customers } = useAppSelector((app) => app.data);

  const getTaskUrl = (task: Task) => {
    const customer = customers.find((c) => c.customerKey === task.customerKey);
    return `C-${customer?.url}/${task.url}`;
  };

  const timeSpent = task.taskTimes
    .filter((t) => t.type === "TIME")
    .reduce((initial, taskTime) => initial + taskTime.durationSeconds, 0);
  const taskUrl = getTaskUrl(task);

  const taskBody = () => (
    <>
      <td>
        <Link className="cut-text" to={`/app/${taskUrl}`}>
          {displayCustomerName && (
            <span className="badge badge-light mr-1">
              {getCustomerName(customers, task.customerKey)}
            </span>
          )}
          {task.name}
        </Link>
        <div className="small text-muted cut-text">{task.description}</div>
      </td>
      <td className="text-center">{formatter.format(task.hourlyRate)}</td>
      <td className="text-center">{formatTimeSpent(timeSpent)}</td>
      <td className="text-center">
        {getEarnings(task.taskTimes, task.hourlyRate)}
      </td>
      {!hideTaskControls && (
        <td className="text-center">
          <TaskListItemAddTime taskKey={task.taskKey} />
        </td>
      )}
    </>
  );

  return (
    <React.Fragment key={`customer_summary_${task.taskKey}`}>
      {noTrItem ? taskBody() : <tr>{taskBody()}</tr>}
    </React.Fragment>
  );
};

export default TaskListItem;
