import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../store";

interface IProps {
  children: React.ReactNode;
}

const PrivateRoute = ({ children }: IProps) => {
  let { pathname } = useLocation();
  const { userKey } = useSelector((app: RootState) => app.user.details);

  if (!userKey) {
    return (
      <Navigate replace to="/login" state={{ from: "location", pathname }} />
    );
  }
  return <>{children}</>;
};
export default PrivateRoute;
