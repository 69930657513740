import axios from "axios";
import { API } from "../config/constants";
import { Report } from "../store/reports/types";
import "./helper";

export const API_getReportList = () =>
  axios.post<Report[]>(`${API}reports`, {
    action: "get_report_list",
  });

export const API_adNewReport = (
  title: string,
  description: string,
  additionalComment: string,
  includeTaskTimeList: boolean,
  customerKey: string,
  taskKeys: string[]
) =>
  axios.post<Report>(`${API}reports`, {
    action: "add_new_report",
    title,
    description,
    additionalComment,
    includeTaskTimeList,
    customerKey,
    taskKeys,
  });

export const API_editReport = (
  title: string,
  description: string,
  additionalComment: string,
  includeTaskTimeList: boolean,
  taskKeys: string[],
  reportKey: string
) =>
  axios.post<Report>(`${API}reports`, {
    action: "edit_report",
    title,
    description,
    additionalComment,
    includeTaskTimeList,
    taskKeys,
    reportKey,
  });
