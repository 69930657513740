import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Report } from "../../../../../store/reports/types";
import { ModalWindow } from "../../../Common";
import TasksNoReportsNewReportModal from "./TasksNoReportsNewReportModal";

interface IProps {
  selectedTasksKeys: string[];
  addNewReport: (newReport: Report) => void;
  customerKey: string;
}

const TasksNoReportsNewReportBtn = ({
  selectedTasksKeys,
  addNewReport,
  customerKey,
}: IProps) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <>
      <button
        className={`btn btn-sm btn-light ${
          selectedTasksKeys.length ? "" : "disabled"
        }`}
        onClick={toggleOpen}
      >
        Create report for selected tasks <FontAwesomeIcon icon={faPlus} />
      </button>
      {open && (
        <ModalWindow closeModalCustomAction={toggleOpen}>
          <TasksNoReportsNewReportModal
            toggleOpen={toggleOpen}
            selectedTasksKeys={selectedTasksKeys}
            addNewReport={addNewReport}
            customerKey={customerKey}
          />
        </ModalWindow>
      )}
    </>
  );
};

export default TasksNoReportsNewReportBtn;
