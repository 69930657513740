import React, { useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../store";
import { Task } from "../../../../store/data/types";
import { FirstDateOfTheCurrentWeek } from "./Calendar";
import { formatHourTwoDecimentals } from "./utils";

interface IProps {
  firstDateOfTheCurrentWeek: FirstDateOfTheCurrentWeek;
}

const CalendarWeek = ({ firstDateOfTheCurrentWeek }: IProps) => {
  const { tasks, customers } = useAppSelector((app) => app.data);
  const taskColors = useRef<string[]>([]);

  const taskClassNameGenerator = (taskKey: string) => {
    const idx = taskColors.current.indexOf(taskKey);
    if (idx !== -1) return idx;
    taskColors.current.push(taskKey);
    return taskColors.current.indexOf(taskKey);
  };

  const firstDateOfTheCurrentWeekDate = useMemo(
    () =>
      new Date(
        firstDateOfTheCurrentWeek.firstWeekYear,
        firstDateOfTheCurrentWeek.firstWeekMonth - 1,
        firstDateOfTheCurrentWeek.firstWeekDay
      ),
    [firstDateOfTheCurrentWeek]
  );

  const tasksSelectedWeek = useMemo(() => {
    const firstDateOfTheCurrentWeekDateTimestamp =
      firstDateOfTheCurrentWeekDate.getTime() / 1000;

    return tasks.filter((task) => {
      const taskTimes = task.taskTimes.filter((t) => {
        return (
          t.stop !== null &&
          t.stop > firstDateOfTheCurrentWeekDateTimestamp &&
          t.start < firstDateOfTheCurrentWeekDateTimestamp
        );
      });
      return { ...task, taskTimes };
    });
  }, [firstDateOfTheCurrentWeekDate, tasks]);

  const renderTasksPerHour = (plusDays: number, tasks: Task[]) => {
    const desiredDate = new Date(firstDateOfTheCurrentWeekDate);
    desiredDate.setDate(desiredDate.getDate() + plusDays);
    const desiredTime = desiredDate.getTime() / 1000;

    const desiredTasks = tasks.filter((task) => {
      const taskTimes = task.taskTimes.find(
        (t) => t.start > desiredTime && t.start < desiredTime + 3600 * 24
      );
      return taskTimes !== undefined;
    });
    if (!desiredTasks.length)
      return <div className="tasks-per-hour-empty-col"></div>;
    return (
      <div className="tasks-per-hour d-flex">
        {desiredTasks.map((t) => {
          const customer = customers.find(
            (c) => c.customerKey === t.customerKey
          );
          return (
            <div
              key={`task_per_hour_${t.taskKey}${plusDays}`}
              className={`task_color_${taskClassNameGenerator(t.taskKey)}`}
            >
              <Link
                className="calendar-task-url"
                to={`/app/C-${customer?.url}/${t.url}`}
              >
                {t.name}
              </Link>
            </div>
          );
        })}
      </div>
    );
  };

  const renderHourRow = (hour: number) => {
    const taskThisHour = tasksSelectedWeek
      .map((task) => {
        const taskTimes = task.taskTimes.filter((tt) => {
          if (tt.stop === null) return false;

          const startTaskTimeHour = new Date(tt.start * 1000).getHours();
          const stopTaskTimeHour = new Date(tt.stop * 1000).getHours();

          return startTaskTimeHour <= hour && stopTaskTimeHour >= hour;
        });
        return { ...task, taskTimes };
      })
      .filter((task) => task.taskTimes.length);

    return (
      <tr key={`row_hour_${hour}`}>
        <td className="text-center">{formatHourTwoDecimentals(hour)}</td>
        <td>{renderTasksPerHour(0, taskThisHour)}</td>
        <td>{renderTasksPerHour(1, taskThisHour)}</td>
        <td>{renderTasksPerHour(2, taskThisHour)}</td>
        <td>{renderTasksPerHour(3, taskThisHour)}</td>
        <td>{renderTasksPerHour(4, taskThisHour)}</td>
        <td>{renderTasksPerHour(5, taskThisHour)}</td>
        <td>{renderTasksPerHour(6, taskThisHour)}</td>
      </tr>
    );
  };
  const renderHours = () => {
    let output: JSX.Element[] = [];

    for (let i = 0; i < 24; i++) {
      output.push(renderHourRow(i));
    }

    return output;
  };

  const getDateForDay = (plusDays?: number) => {
    const desiredDate = new Date(firstDateOfTheCurrentWeekDate);
    desiredDate.setDate(desiredDate.getDate() + (plusDays || 0));
    return `${desiredDate.getDate()}/${desiredDate.getMonth() + 1}`;
  };

  return (
    <table className="table table-bordered calendar-table">
      <thead>
        <tr>
          <th className="clendar-hour-col"></th>
          <th>Sun {getDateForDay()}</th>
          <th>Mon {getDateForDay(1)}</th>
          <th>Tue {getDateForDay(2)}</th>
          <th>Wed {getDateForDay(3)}</th>
          <th>Thu {getDateForDay(4)}</th>
          <th>Fri {getDateForDay(5)}</th>
          <th>Sat {getDateForDay(6)}</th>
        </tr>
      </thead>
      <tbody>{renderHours()}</tbody>
    </table>
  );
};

export default CalendarWeek;
