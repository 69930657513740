import React, { useEffect, useState } from "react";
import { API_adNewReport } from "../../../../../services/reports";
import { useAppDispatch } from "../../../../../store";
import { removeTasksByKey } from "../../../../../store/data/actions";
import { Report } from "../../../../../store/reports/types";
import Loader from "../../../../Loader";
import { ModalProps } from "../../../Common/Modal/ModalWindow";
import NewReportForm from "./NewReportForm";

interface IProps extends ModalProps {
  toggleOpen: () => void;
  customerKey: string;
  selectedTasksKeys: string[];
  addNewReport: (newReport: Report) => void;
}

const TasksNoReportsNewReportModal = ({
  toggleOpen,
  setTitle,
  customerKey,
  selectedTasksKeys,
  addNewReport
}: IProps) => {
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (setTitle) setTitle("Create new report");
  }, [setTitle]);

  const createReportAction = (
    reportTitle: string,
    reportDescription: string,
    reportAdditionalComment: string,
    reportIncludeTaskTimeList: boolean
  ) => {
    setLoader(true);

    API_adNewReport(
      reportTitle,
      reportDescription,
      reportAdditionalComment,
      reportIncludeTaskTimeList,
      customerKey,
      selectedTasksKeys
    )
      .then((updatedReport) => {
        addNewReport(updatedReport.data);
        dispatch(removeTasksByKey(selectedTasksKeys));
        setError("");
        setLoader(false);
        toggleOpen();
      })
      .catch((errorMessage) => {
        setError(errorMessage);
        setLoader(false);
      });
  };

  if (loader) return <Loader />;
  return (
    <>
      {error.length > 0 && <div className="alert alert-danger">{error}</div>}
      <NewReportForm
        saveDataAction={createReportAction}
        saveDataTxt="Create report"
        cancelAction={toggleOpen}
      />
    </>
  );
};

export default TasksNoReportsNewReportModal;
