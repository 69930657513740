import { LOGOUT } from "../app/types";

export const SET_USER = "SET_USER";

export interface User {
  details: UserDetails;
  billing: UserBilling;
}

export type AccountType = "standard" | "premium";

export interface UserDetails {
  userKey: string;
  userName: string;
  email: string;
  accountType: AccountType;
}

export interface UserBilling {
  companyName: string;
  companyAddress: string;
  companyAddress2nd: string;
  companyCity: string;
  companyZip: string;
  companyState: string;
  companyCountryCode: string;
  taxId: string;
  stripeId: string;
}

// redux
interface SetUser {
  type: typeof SET_USER;
  payload: User;
}

interface Logout {
  type: typeof LOGOUT;
}

export type UserActionTypes = SetUser | Logout;
