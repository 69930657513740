import axios from "axios";
import { API } from "../config/constants";
import { YearlyStats } from "../store/statistics/types";
import "./helper";

export type StatsType = "all" | "active";

export const API_getStatisticsYearly = (year: number, type: StatsType) =>
  axios.post<YearlyStats>(`${API}statistics`, {
    action: "get_statistics_yearly",
    year,
    type,
  });
