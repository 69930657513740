import React from "react";
import { useState } from "react";
import { Customer } from "../../../../store/data/types";

import CustomerDetailEdit from "./CustomerDetailEdit";
import CustomerDetailPreview from "./CustomerDetailPreview";

interface IProps {
  closeModal?: () => void;
  customerDetail: Customer;
}

const CustomerDetailData = ({ closeModal, customerDetail }: IProps) => {
  const [edit, setEdit] = useState(false);
  const toggleEdit = () => setEdit(!edit);

  if (edit)
    return (
      <CustomerDetailEdit
        toggleEdit={toggleEdit}
        customerDetail={customerDetail}
        closeModal={closeModal}
      />
    );

  return (
    <CustomerDetailPreview
      customerDetail={customerDetail}
      toggleEdit={toggleEdit}
    />
  );
};

export default CustomerDetailData;
