import React from "react";
import { Route, Routes } from "react-router-dom";
import AccountForm from "./AccountForm";
import AccountUpgrade from "./AccountUpgrade";

const Account = () => (
  <div className="container-fluid">
    <div className="row">
      <div className="col-12">
        <Routes>
          <Route path="/" element={<AccountForm />} />
          <Route path="/upgrade" element={<AccountUpgrade />} />
        </Routes>
      </div>
    </div>
  </div>
);
export default Account;
