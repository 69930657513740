import { LOGOUT } from "../app/types";
import {
  Data,
  ADD_CUSTOMERS,
  DataActionTypes,
  EDIT_CUSTOMER,
  ADD_TASKS,
  EDIT_TASK,
  REMOVE_TASKS_BY_KEY,
  ADD_TASK_TIME,
  EDIT_TASK_TIME,
  REMOVE_TASKS_TIMES,
  REMOVE_CUSTOMER,
} from "./types";

const initialState = {
  customers: [],
  tasks: [],
};

export const dataReducer = (
  state: Data = initialState,
  action: DataActionTypes
): Data => {
  switch (action.type) {
    // templates
    case ADD_CUSTOMERS:
      return {
        ...state,
        customers: [...state.customers, ...action.payload],
      };
    case EDIT_CUSTOMER:
      return {
        ...state,
        customers: state.customers.map((cust) =>
          cust.customerKey === action.payload.customerKey
            ? action.payload
            : cust
        ),
      };
    case REMOVE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.filter(
          (customer) => customer.customerKey !== action.payload
        ),
      };
    case ADD_TASKS:
      return {
        ...state,
        tasks: [...state.tasks, ...action.payload],
      };
    case EDIT_TASK:
      return {
        ...state,
        tasks: state.tasks.map((task) =>
          task.taskKey === action.payload.taskKey ? action.payload : task
        ),
      };
    case REMOVE_TASKS_BY_KEY:
      return {
        ...state,
        tasks: state.tasks.filter(
          (task) => !action.payload.includes(task.taskKey)
        ),
      };
    case ADD_TASK_TIME:
      return {
        ...state,
        tasks: state.tasks.map((task) => {
          if (task.taskKey === action.payload.taskId)
            return {
              ...task,
              taskTimes: [...task.taskTimes, action.payload.taskTime],
            };
          else return task;
        }),
      };
    case EDIT_TASK_TIME:
      return {
        ...state,
        tasks: state.tasks.map((task) => {
          if (task.taskKey === action.payload.taskId)
            return {
              ...task,
              taskTimes: task.taskTimes.map((taskTimeItem) =>
                taskTimeItem.taskTimeKey === action.payload.taskTime.taskTimeKey
                  ? action.payload.taskTime
                  : taskTimeItem
              ),
            };
          else return task;
        }),
      };
    case REMOVE_TASKS_TIMES:
      return {
        ...state,
        tasks: state.tasks.map((task) => {
          const matchingTask = action.payload.filter(
            (t) => t.taskKey === task.taskKey
          );
          if (!matchingTask.length) return task;

          return {
            ...task,
            taskTimes: task.taskTimes.filter(
              (taskTimeItem) =>
                matchingTask.find(
                  (mt) => mt.taskTimeKey === taskTimeItem.taskTimeKey
                ) === undefined
            ),
          };
        }),
      };
    // other
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
