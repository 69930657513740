import React from "react";
import { useAppSelector } from "../../../../../store";
import { Customer } from "../../../../../store/data/types";
import { Report } from "../../../../../store/reports/types";
import TasksNoReportsPerCustomer from "./TasksNoReportsPerCustomer";

interface IProps {
  reports: Report[];
  addNewReport: (newReport: Report) => void;
  updateReport: (updatedReport: Report) => void;
}

const TasksNoReports = ({ reports, addNewReport, updateReport }: IProps) => {
  const { customers } = useAppSelector((app) => app.data);

  const renderTasksForCustomer = (customer: Customer) => (
    <TasksNoReportsPerCustomer
      key={`tasks_per_customer_${customer.customerKey}`}
      customer={customer}
      reports={reports}
      addNewReport={addNewReport}
      updateReport={updateReport}
    />
  );

  return (
    <div className="card bg-white border-light mb-3">
      <div className="card-body p-4">
        <h6>Tasks without reports</h6>
        {customers.length > 0 ? (
          <ul className="list-group list-group-flush mt-3">
            {customers.map(renderTasksForCustomer)}
          </ul>
        ) : (
          <div>No tasks && customers found.</div>
        )}
      </div>
    </div>
  );
};

export default TasksNoReports;
