const TaskActiveTimeListItemHeader = () => (
  <thead className="bg-200 text-800">
    <tr>
      <th className="text-nowrap">Start</th>
      <th className="text-nowrap">End</th>
      <th className="text-nowrap">Time spent</th>
      <th className="text-nowrap task-time-item-earnings">Earnings</th>
      <th className="text-nowrap task-time-item-settings"></th>
    </tr>
  </thead>
);

export default TaskActiveTimeListItemHeader;
