import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API_editUserBilling } from "../../../../services/user";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setUser } from "../../../../store/user/actions";
import { UserBilling } from "../../../../store/user/types";
import Loader from "../../../Loader";
import {
  faAddressCard,
  faFlag,
  faMap,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { DETAUL_EMPTY_USER_BILLING } from "../../../../Utils/constants";

const COUNTRY_CODES: {
  code: string;
  name: string;
}[] = require("./countryCodes.json");

const InvoiceDataForm = () => {
  const dispatch = useAppDispatch();
  const { billing } = useAppSelector((app) => app.user);
  const [userBilling, setUserBilling] = useState<UserBilling>(
    DETAUL_EMPTY_USER_BILLING
  );
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setUserBilling(billing);
  }, [billing]);

  const partialUpdateUserBilling = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fieldName = event.currentTarget.dataset.fieldname;
    const fieldValue = event.target.value;
    if (!fieldName) return;
    setUserBilling({ ...userBilling, [fieldName]: fieldValue });
  };

  const updateCountry = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountryCode = event.target.value;
    if (!selectedCountryCode) return;
    setUserBilling({ ...userBilling, companyCountryCode: selectedCountryCode });
  };

  const saveUserBilling = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoader(true);
    API_editUserBilling(userBilling)
      .then((response) => {
        dispatch(setUser(response.data));
        setError("");
        setLoader(false);
      })
      .catch((errorMessage) => {
        setError(errorMessage);
        setLoader(false);
      });
  };

  if (loader) return <Loader />;
  return (
    <div className="card bg-white border-light p-3 mb-4">
      {error.length > 0 && <div className="alert alert-danger">{error}</div>}
      <div className="card-body p-4">
        <h4 className="mb-4 mb-lg-5">Billing information</h4>
        <form onSubmit={saveUserBilling}>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="form-label h6" htmlFor="companyName">
                  Company Name
                </label>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faAddressCard} />
                    </span>
                  </div>
                  <input
                    className="form-control"
                    id="companyName"
                    data-fieldname="companyName"
                    type="text"
                    value={userBilling.companyName}
                    onChange={partialUpdateUserBilling}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label className="form-label h6" htmlFor="companyAddress">
                  Address line 1 (e.g., street, PO Box, or company name).
                </label>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faMap} />
                    </span>
                  </div>
                  <input
                    className="form-control"
                    id="companyAddress"
                    data-fieldname="companyAddress"
                    type="text"
                    value={userBilling.companyAddress}
                    onChange={partialUpdateUserBilling}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label className="form-label h6" htmlFor="companyAddress2nd">
                  Address line 2 (e.g., apartment, suite, unit, or building).
                </label>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faMap} />
                    </span>
                  </div>
                  <input
                    className="form-control"
                    id="companyAddress2nd"
                    data-fieldname="companyAddress2nd"
                    type="text"
                    value={userBilling.companyAddress2nd}
                    onChange={partialUpdateUserBilling}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label className="form-label h6" htmlFor="companyCity">
                  City
                </label>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faMap} />
                    </span>
                  </div>
                  <input
                    className="form-control"
                    id="companyCity"
                    data-fieldname="companyCity"
                    type="text"
                    value={userBilling.companyCity}
                    onChange={partialUpdateUserBilling}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label className="form-label h6" htmlFor="companyZip">
                  ZIP or postal code
                </label>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faMap} />
                    </span>
                  </div>
                  <input
                    className="form-control"
                    id="companyZip"
                    data-fieldname="companyZip"
                    type="text"
                    value={userBilling.companyZip}
                    onChange={partialUpdateUserBilling}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label className="form-label h6" htmlFor="companyState">
                  State, county, province, or region.
                </label>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faMap} />
                    </span>
                  </div>
                  <input
                    className="form-control"
                    id="companyState"
                    data-fieldname="companyState"
                    type="text"
                    value={userBilling.companyState}
                    onChange={partialUpdateUserBilling}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label className="form-label h6" htmlFor="companyCountry">
                  Country
                </label>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faFlag} />
                    </span>
                  </div>
                  <select
                    className="custom-select"
                    onChange={updateCountry}
                    defaultValue={
                      billing.companyCountryCode &&
                      billing.companyCountryCode.length
                        ? billing.companyCountryCode
                        : "US"
                    }
                  >
                    {COUNTRY_CODES.map((countryData) => (
                      <option
                        value={countryData.code}
                        key={`country_${countryData.code}`}
                      >
                        {countryData.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label className="form-label h6" htmlFor="taxId">
                  Taxpayer Identification Numbers (TIN){" "}
                  <small>(if needed)</small>
                </label>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <FontAwesomeIcon icon={faTable} />
                    </span>
                  </div>
                  <input
                    className="form-control"
                    id="taxId"
                    data-fieldname="taxId"
                    type="text"
                    value={userBilling.taxId}
                    onChange={partialUpdateUserBilling}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row text-right">
            <div className="col-12">
              <button type="submit" className="btn btn-sm btn-primary">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InvoiceDataForm;
