import React, { useState } from "react";
import { API_editReport } from "../../../../../services/reports";
import { Report } from "../../../../../store/reports/types";
import Loader from "../../../../Loader";
import NewReportForm from "../TasksNoReports/NewReportForm";

interface IProps {
  report: Report;
  toggleEdit: () => void;
  updateReport: (updatedReport: Report) => void;
}

const ManageExistingReportsItemEdit = ({
  report,
  toggleEdit,
  updateReport,
}: IProps) => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  const updateReportAction = (
    title: string,
    description: string,
    additionalComment: string,
    includeTaskTimeList: boolean,
  ) => {
    setLoader(true);

    API_editReport(
      title,
      description,
      additionalComment,
      includeTaskTimeList,
      report.taskKeyList,
      report.reportKey
    )
      .then((updatedReport) => {
        updateReport(updatedReport.data);
        setError("");
        setLoader(false);
        toggleEdit();
      })
      .catch((errorMessage) => {
        setError(errorMessage);
        setLoader(false);
      });
  };
  if (loader) return <Loader />;
  return (
    <>
      {error.length > 0 && <div className="alert alert-danger">{error}</div>}
      <NewReportForm
        defaultData={{
          reportTitle: report.title,
          reportDescription: report.description,
          reportAdditionalComment: report.additionalComment,
          reportIncludeTaskTimeList: report.includeTaskTimeList,
        }}
        saveDataAction={updateReportAction}
        saveDataTxt="Edit report"
        cancelAction={toggleEdit}
      />
    </>
  );
};

export default ManageExistingReportsItemEdit;
