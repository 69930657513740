import React, { useEffect, useState } from "react";
import { API_editReport } from "../../../../../services/reports";
import { useAppDispatch } from "../../../../../store";
import { removeTasksByKey } from "../../../../../store/data/actions";
import { Report } from "../../../../../store/reports/types";
import Loader from "../../../../Loader";
import { ModalProps } from "../../../Common/Modal/ModalWindow";

interface IProps extends ModalProps {
  toggleOpen: () => void;
  selectedTasksKeys: string[];
  customerReports: Report[];
  updateReport: (updatedReport: Report) => void;
}

const TasksNoReportsAssignToExistingReportModal = ({
  toggleOpen,
  setTitle,
  customerReports,
  selectedTasksKeys,
  updateReport,
}: IProps) => {
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (setTitle) setTitle("Assign a task to the existing report");
  }, [setTitle]);

  const assignTasksToReportAction = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const reportKey = event.currentTarget.dataset.reportkey;
    if (!reportKey) return;
    const report = customerReports.find((r) => r.reportKey === reportKey);
    if (!report) return;
    setLoader(true);

    API_editReport(
      report.title,
      report.description,
      report.additionalComment,
      report.includeTaskTimeList,
      [...report.taskKeyList, ...selectedTasksKeys],
      report.reportKey
    )
      .then((updatedReport) => {
        updateReport(updatedReport.data);
        dispatch(removeTasksByKey(selectedTasksKeys));
        setError("");
        setLoader(false);
        toggleOpen();
      })
      .catch((errorMessage) => {
        setError(errorMessage);
        setLoader(false);
      });
  };

  if (loader) return <Loader />;
  return (
    <>
      {error.length > 0 && <div className="alert alert-danger">{error}</div>}
      {customerReports.map((report) => (
        <div
          key={`assign_to_report_${report.reportKey}`}
          className="d-flex mb-2 pb-2 border-bottom"
        >
          <button
            className="btn btn-sm btn-light mr-2"
            onClick={assignTasksToReportAction}
            data-reportkey={report.reportKey}
          >
            Assign
          </button>
          <div>
            {report.title.length > 0 ? `${report.title}, ` : "Report "}{" "}
            generated on {report.generatedTime}
          </div>
        </div>
      ))}
    </>
  );
};

export default TasksNoReportsAssignToExistingReportModal;
