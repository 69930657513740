import { LOGOUT } from "../app/types";

export const ADD_CUSTOMERS = "ADD_CUSTOMERS";
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const REMOVE_CUSTOMER = "REMOVE_CUSTOMER";

export const ADD_TASKS = "ADD_TASKS";
export const EDIT_TASK = "EDIT_TASK";
export const REMOVE_TASKS_BY_KEY = "REMOVE_TASKS_BY_KEY";

export const ADD_TASK_TIME = "ADD_TASK_TIME";
export const EDIT_TASK_TIME = "EDIT_TASK_TIME";
export const REMOVE_TASKS_TIMES = "REMOVE_TASKS_TIMES";

export interface Data {
  customers: Customer[];
  tasks: Task[];
}

export interface Customer {
  customerKey: string;
  name: string;
  url: string;
  hourlyRate: number;
  description: string;
  status: "active" | "archived";
}
export interface CheckUrlAvailability {
  status: "ok" | "taken";
  otherUrlProposal: string;
}

export interface Task {
  taskKey: string;
  name: string;
  description: string;
  url: string;
  hourlyRate: number;
  status: "active" | "archived";
  order: number;
  created: string;
  customerKey: string;
  taskTimes: TaskTime[];
}

export type TaskTimeType = "TIME" | "AMOUNT";

export interface TaskTime {
  taskTimeKey: string;
  start: number;
  stop: number | null;
  durationSeconds: number;
  description: string | null;
  amount: number | null;
  type: TaskTimeType;
}

/* 
  redux
*/
interface AddCustomers {
  type: typeof ADD_CUSTOMERS;
  payload: Customer[];
}
interface EditCustomer {
  type: typeof EDIT_CUSTOMER;
  payload: Customer;
}
interface RemoveCustomerByKey {
  type: typeof REMOVE_CUSTOMER;
  payload: string;
}
/* ----- */
interface AddTasks {
  type: typeof ADD_TASKS;
  payload: Task[];
}
interface EditTask {
  type: typeof EDIT_TASK;
  payload: Task;
}
interface RemoveTasksByKey {
  type: typeof REMOVE_TASKS_BY_KEY;
  payload: string[];
}
/* ----- */
interface AddTaskTime {
  type: typeof ADD_TASK_TIME;
  payload: {
    taskId: string;
    taskTime: TaskTime;
  };
}
interface EditTaskTime {
  type: typeof EDIT_TASK_TIME;
  payload: {
    taskId: string;
    taskTime: TaskTime;
  };
}
interface RemoveTasksTimes {
  type: typeof REMOVE_TASKS_TIMES;
  payload: {
    taskKey: string;
    taskTimeKey: string;
  }[];
}

interface Logout {
  type: typeof LOGOUT;
}

export type DataActionTypes =
  | AddCustomers
  | EditCustomer
  | RemoveCustomerByKey
  | AddTasks
  | EditTask
  | RemoveTasksByKey
  | AddTaskTime
  | EditTaskTime
  | RemoveTasksTimes
  | Logout;
