import React, { useMemo } from "react";
import { useAppSelector } from "../../../../../store";
import { Customer } from "../../../../../store/data/types";
import { Report } from "../../../../../store/reports/types";
import ManageExistingReportsItem from "./ManageExistingReportsItem";

interface IProps {
  reports: Report[];
  updateReport: (updatedReport: Report) => void;
}
const ManageExistingReports = ({ reports, updateReport }: IProps) => {
  const { customers } = useAppSelector((app) => app.data);

  const uniqueCustomers = useMemo(() => {
    const customerSet = Array.from(
      new Set(reports.map((r) => r.customerKey))
    ).map((customerKey) => {
      const customer = customers.find((c) => c.customerKey === customerKey);
      return customer;
    });

    return customerSet.filter(
      (customer) => customer?.customerKey
    ) as Customer[];
  }, [customers, reports]);

  const renderByCustomer = (customer: Customer) => (
    <li
      className="list-group-item p-0 mb-3 border"
      key={`report_set_for_customer_${customer.customerKey}`}
    >
      <div className="border-bottom bg-light pl-2 py-1">{customer.name}</div>
      <ul className="list-group list-group-flush px-2">
        {reports
          .filter((r) => r.customerKey === customer.customerKey)
          .map(renderReportItem)}
      </ul>
    </li>
  );

  const renderReportItem = (report: Report) => (
    <ManageExistingReportsItem
      report={report}
      updateReport={updateReport}
      key={`modal_detail_report_${report.reportKey}`}
    />
  );

  return (
    <div className="card bg-white border-light mb-3">
      <div className="card-body p-4">
        <h6>Manage reports</h6>
        {reports.length > 0 ? (
          <ul className="list-group list-group-flush mt-3">
            {uniqueCustomers.map(renderByCustomer)}
          </ul>
        ) : (
          "Please create first report"
        )}
      </div>
    </div>
  );
};

export default ManageExistingReports;
