import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { API_passwordRemindRequest } from "../../services/auth";
import { emailIsValid } from "../../Utils";
import Loader from "../Loader";

interface IProps {
  setupUsernameForPasswordChange: (user: string) => void;
}

const ForgotPasswordFormBase = ({ setupUsernameForPasswordChange }: IProps) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const allowSubmit = () => emailIsValid(email);

  const setupEmail = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);

  const remindPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoader(true);
    API_passwordRemindRequest(email)
      .then(() => {
        setupUsernameForPasswordChange(email);
      })
      .catch((errorMessage) => {
        setLoader(false);
        setError(errorMessage);
      });
  };

  if (loader) return <Loader />;

  return (
    <section className="vh-100 bg-soft d-flex align-items-center">
      <div className="container">
        <div
          className="row justify-content-center form-bg-image"
          data-background="../assets/img/illustrations/signin.svg"
        >
          <div className="col-12 d-flex align-items-center justify-content-center signup-container">
            <div className="signin-inner mt-3 mt-lg-0 bg-white shadow-soft border border-light rounded p-4 p-lg-5 w-100 fmxw-500">
              <div className="text-center text-md-center mb-4 mt-md-0">
                <h1 className="mb-3 h3">Forgotten password</h1>
                <p className="text-gray">
                  Enter your email address to reset your password.
                </p>
              </div>
              {error.length > 0 && (
                <div className="alert alert-danger">{error}</div>
              )}
              <form className="mt-5" onSubmit={remindPassword}>
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                      required
                      onChange={setupEmail}
                      value={email}
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <button
                    type="submit"
                    className="btn btn-block btn-primary"
                    disabled={!allowSubmit()}
                  >
                    Remin password
                  </button>
                </div>
              </form>
              <div className="d-block d-sm-flex justify-content-center align-items-center mt-4">
                <span className="font-weight-normal">
                  <Link to="/login/signin" className="small">
                   Go back
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordFormBase;
