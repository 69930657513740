import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { API } from "../../../../config/constants";
import { RootState } from "../../../../store";
import { AccountType } from "../../../../store/user/types";

interface ColContent {
  type: string;
  typeSelect: AccountType;
  price: string;
  priceClass: string;
  header: string;
  pointClass: string;
  points: JSX.Element[];
  upgradeBtn: boolean;
  upgradeBtnClass: string;
  noUpgradeReason?: string;
  apiId: string;
}

const COL_CONTENT_FREE: ColContent = {
  type: "Standard",
  typeSelect: "standard",
  price: "0",
  priceClass: "",
  header: "If you just need the basics, this plan is a great way.",
  pointClass: "icon-gray",
  points: [
    <>
      Customers: <span className="font-weight-bolder"> 1</span>
    </>,
    <>
      Active tasks: <span className="font-weight-bolder"> 5</span>
    </>,
    <>
      Recorded task times for active tasks:
      <span className="font-weight-bolder"> 40</span>
    </>,
    <>
      You can always archive a task and create a new one
    </>,
    <>Create reports and share them with a unique customer summary url</>,
  ],
  upgradeBtn: false,
  upgradeBtnClass: "",
  apiId: "",
};
const COL_CONTENT_PREMIUM: ColContent = {
  type: "Premium",
  typeSelect: "premium",
  price: "6.99",
  priceClass: "text-primary",
  header:
    "Ample limits plus full access to templates and use of multiple api keys. Most folks start here.",
  pointClass: "icon-primary",
  points: [
    <>
      Customers: <span className="font-weight-bolder"> 50</span>
    </>,
    <>
      Active tasks: <span className="font-weight-bolder"> 200</span>
    </>,
    <>
      Recorded task times for active tasks:
      <span className="font-weight-bolder"> unlimited</span>
    </>,
    <>Create reports and share them with a unique customer summary url</>,
  ],
  upgradeBtn: true,
  upgradeBtnClass: "btn-outline-primary",
  apiId: "price_1JQTM9Cxu9It1JqpJebOznqP",
};

const AccountUpgradeSelect = () => {
  const { details, billing } = useSelector((app: RootState) => app.user);

  const renderCol = (colContent: ColContent) => (
    <div className="col-12 col-md-6" key={`col_${colContent.price}`}>
      <div className="card shadow-soft mb-5 mb-lg-6 px-2">
        <div className="card-header border-light py-5 px-4">
          <div className="d-flex mb-3">
            <span className="h5 mb-0">$</span>
            <span className={`price display-2 mb-0 ${colContent.priceClass}`}>
              {colContent.price}
            </span>
            <span className="h6 font-weight-normal align-self-end">/month</span>
          </div>
          <h4 className="mb-3 text-black">{colContent.type}</h4>
          <p className="font-weight-normal mb-0">{colContent.header}</p>
        </div>
        <div className="card-body pt-5">
          <ul className="list-group simple-list">
            {colContent.points.map((point, idx) => (
              <li className="list-group-item font-weight-normal" key={idx}>
                <span className={colContent.pointClass}>
                  <FontAwesomeIcon icon={faCheck} className="mr-2" />
                </span>
                {point}
              </li>
            ))}
          </ul>
        </div>
        {colContent.upgradeBtn && (
          <div className="card-footer px-4 pb-4">
            <form action={`${API}payment/checkout.php`} method="POST">
              <input type="hidden" name="price_id" value={colContent.apiId} />
              <input type="hidden" name="user_key" value={details.userKey} />

              <input
                type="hidden"
                name="company_name"
                value={billing.companyName}
              />
              <input
                type="hidden"
                name="company_address"
                value={billing.companyAddress}
              />
              <input
                type="hidden"
                name="company_address_2nd"
                value={billing.companyAddress2nd}
              />
              <input
                type="hidden"
                name="company_city"
                value={billing.companyCity}
              />
              <input
                type="hidden"
                name="company_zip"
                value={billing.companyZip}
              />
              <input
                type="hidden"
                name="company_state"
                value={billing.companyState}
              />
              <input
                type="hidden"
                name="company_country_code"
                value={billing.companyCountryCode}
              />
              <input type="hidden" name="tax_id" value={billing.taxId} />
              <input type="hidden" name="stripe_id" value={billing.stripeId} />
              <button
                data-package={colContent.typeSelect}
                className={`btn btn-block ${colContent.upgradeBtnClass}`}
                type="submit"
              >
                Upgrade
                <span className="icon icon-xs ml-3">
                  <i className="fas fa-arrow-right"></i>
                </span>
              </button>
            </form>
          </div>
        )}
        {colContent.noUpgradeReason && (
          <div className="text-secondary card-footer pt-0">
            {colContent.noUpgradeReason}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="row text-gray">
      {renderCol(COL_CONTENT_FREE)}
      {renderCol(COL_CONTENT_PREMIUM)}
    </div>
  );
};

export default AccountUpgradeSelect;
