import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../store";
import { ModalProps } from "../../Common/Modal/ModalWindow";
import TaskActiveTimeDate from "./TaskActiveTimeDate";
import TaskTimeEditAmount from "./TaskTimeEditAmount";
import TaskTimeEditDescription from "./TaskTimeEditDescription";
import TaskTimeEditRemoveTaskTime from "./TaskTimeEditRemoveTaskTime";
import TaskTimeEditResumeTime from "./TaskTimeEditResumeTime";

interface IProps extends ModalProps {}

const TaskTimeFormEdit = ({ setTitle, closeModal }: IProps) => {
  const params = useParams();
  const { tasks, customers } = useAppSelector((app) => app.data);

  const { customerUrl, taskUrl, taskTimeKey } = params;
  const taskCusomer = customers.find((c) => c.url === customerUrl);
  const taskDetail = tasks.find(
    (task) =>
      task.url.toString() === taskUrl &&
      task.customerKey === taskCusomer?.customerKey
  );
  const taskTime = taskDetail?.taskTimes.find(
    (taskTime) => taskTime.taskTimeKey === taskTimeKey
  );

  useEffect(() => {
    if (setTitle) setTitle(`Edit task time`);
  }, [setTitle]);

  if (!taskTime || !taskDetail)
    return (
      <div className="alert alert-danger">
        Sorry, this task time item doesn't exist :(
      </div>
    );

  return (
    <table className="table table-responsive freelancer-table small">
      <tbody>
        <tr>
          <td className="">Start:</td>
          <td>
            <TaskActiveTimeDate
              taskKey={taskDetail.taskKey}
              taskTime={taskTime}
              toEdit={"start"}
            />
          </td>
        </tr>
        <tr>
          <td>Stop:</td>
          <td>
            {taskTime.stop ? (
              <TaskActiveTimeDate
                taskKey={taskDetail.taskKey}
                taskTime={taskTime}
                toEdit={"stop"}
              />
            ) : (
              <span className="badge badge-success">Active</span>
            )}
          </td>
        </tr>
        {taskTime.type === "AMOUNT" && (
          <tr>
            <td>Amount:</td>
            <td>
              <TaskTimeEditAmount
                taskTime={taskTime}
                taskKey={taskDetail.taskKey}
              />
            </td>
          </tr>
        )}
        <tr>
          <td>Additional description:</td>
          <td>
            <TaskTimeEditDescription
              taskTime={taskTime}
              taskKey={taskDetail.taskKey}
            />
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div className="d-flex justify-content-end">
              <TaskTimeEditResumeTime
                taskTime={taskTime}
                taskKey={taskDetail.taskKey}
                closeModal={closeModal}
              />
              <TaskTimeEditRemoveTaskTime
                taskTime={taskTime}
                taskKey={taskDetail.taskKey}
                closeModal={closeModal}
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default TaskTimeFormEdit;
