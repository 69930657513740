import axios from "axios";
import { API } from "../config/constants";
import { Customer, CheckUrlAvailability } from "../store/data/types";
import "./helper";

export const API_getCustomerList = () =>
  axios.post<Customer[]>(`${API}customers`, {
    action: "get_customer_list",
  });

export const API_addNewCustomer = (
  name: string,
  description: string,
  hourlyRate: string,
  customerUrl: string
) =>
  axios.post<Customer>(`${API}customers`, {
    action: "add_customer",
    name,
    description,
    hourlyRate,
    customerUrl,
  });
export const API_editCustomer = (
  customerKey: string,
  name: string,
  description: string,
  hourlyRate: string,
  customerUrl: string
) =>
  axios.put<Customer>(`${API}customers`, {
    action: "edit_customer",
    customerKey,
    name,
    description,
    hourlyRate,
    customerUrl,
  });
export const API_checkCustomerNameAvailability = (
  customerUrl: string,
  customerKey?: string
) =>
  axios.post<CheckUrlAvailability>(`${API}customers`, {
    action: "check_customer_url_availability",
    customerUrl,
    customerKey,
  });
export const API_archiveCustomer = (customerKey?: string) =>
  axios.post<Customer>(`${API}customers`, {
    action: "archive_customer",
    customerKey,
  });
