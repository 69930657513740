import React, { useEffect, useState } from "react";

interface IProps {
  defaultData?: {
    reportTitle: string;
    reportDescription: string;
    reportAdditionalComment: string;
    reportIncludeTaskTimeList: boolean;
  };
  saveDataAction: (
    reportTitle: string,
    reportDescription: string,
    reportAdditionalComment: string,
    reportIncludeTaskTimeList: boolean
  ) => void;
  saveDataTxt: string;
  cancelAction?: () => void;
}

const NewReportForm = ({
  defaultData,
  saveDataAction,
  saveDataTxt,
  cancelAction,
}: IProps) => {
  const [reportTitle, setReportTitle] = useState("");
  const [reportDescription, setReportDescription] = useState("");
  const [reportAdditionalComment, setReportAdditionalComment] = useState("");
  const [reportIncludeTaskTimeList, setReportIncludeTaskTimeList] = useState(true);

  useEffect(() => {
    if (!defaultData) return;
    setReportTitle(defaultData.reportTitle);
    setReportDescription(defaultData.reportDescription);
    setReportAdditionalComment(defaultData.reportAdditionalComment);
    setReportIncludeTaskTimeList(defaultData.reportIncludeTaskTimeList);
  }, [defaultData]);

  const updateReportTitle = (event: React.ChangeEvent<HTMLInputElement>) =>
    setReportTitle(event.target.value);
  const updateReportDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => setReportDescription(event.target.value);
  const updateReportAdditionalComment = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => setReportAdditionalComment(event.target.value);
  const updateReportIncludeTaskTimes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setReportIncludeTaskTimeList(event.target.checked);

  const saveData = () =>
    saveDataAction(
      reportTitle,
      reportDescription,
      reportAdditionalComment,
      reportIncludeTaskTimeList
    );

  return (
    <form className="">
      <div className="form-group">
        <label htmlFor="report-title">
          <small>(optional)</small> Report title
        </label>
        <input
          type="text"
          className="form-control"
          id="report-title"
          onChange={updateReportTitle}
          value={reportTitle}
          maxLength={500}
        />
      </div>
      <div className="form-group">
        <label htmlFor="customer-description">
          <small>(optional)</small> Description
        </label>
        <textarea
          className="form-control"
          id="customer-description"
          rows={2}
          onChange={updateReportDescription}
          value={reportDescription}
        ></textarea>
      </div>
      <div className="form-group">
        <label htmlFor="additional-comment">
          <small>(optional)</small> Additional comment{" "}
          <small>(will be visible in the footer of the report)</small>
        </label>
        <textarea
          className="form-control"
          id="additional-comment"
          rows={2}
          onChange={updateReportAdditionalComment}
          value={reportAdditionalComment}
        ></textarea>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="include-list-task-times"
          onChange={updateReportIncludeTaskTimes}
          checked={reportIncludeTaskTimeList}
        />
        <label className="form-check-label" htmlFor="include-list-task-times">
          Include a list of task times
        </label>
      </div>

      <div className="mt-3">
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={saveData}
        >
          {saveDataTxt}
        </button>
        {cancelAction && (
          <button className="btn btn-link btn-sm ml-3" onClick={cancelAction}>
            Cancel
          </button>
        )}
      </div>
    </form>
  );
};

export default NewReportForm;
