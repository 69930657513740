import React, { useEffect, useState } from "react";
import { TaskTimeType } from "../../../../store/data/types";
import { ModalProps } from "../../Common/Modal/ModalWindow";

interface IProps extends ModalProps {
  addTaskTimeAction: (type: TaskTimeType, amount?: string, description?: string) => void;
}

const TaskListItemAddTimeAmountType = ({
  addTaskTimeAction,
  closeModal,
  setTitle,
}: IProps) => {
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");

  const updateDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setDescription(event.target.value);

  useEffect(() => {
    if (setTitle) setTitle("Add `amount` type task time");
  });

  const addNewTaskTimeAmount = () => {
    addTaskTimeAction("AMOUNT", amount, description);
  };

  const updateAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(",", ".");
    if (parseFloat(value) > 99999) return;
    if (value.indexOf(".") !== -1) {
      const divided = value.split(".");
      value = divided[0] + "." + divided[1].slice(0, 2);
    }
    setAmount(value);
  };

  return (
    <form className="">
      <table className="table table-responsive freelancer-table small">
        <tbody>
          <tr>
            <td>Amount</td>
            <td>
              <input
                type="number"
                className="form-control form-control-sm"
                id="task-time-item-amount"
                onChange={updateAmount}
                value={amount}
                placeholder="0"
                required
              />
            </td>
          </tr>
          <tr>
            <td>Additional description:</td>
            <td>
              <textarea
                className="form-control"
                rows={3}
                value={description}
                onChange={updateDescription}
              ></textarea>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="mt-3">
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  onClick={addNewTaskTimeAmount}
                >
                  Add
                </button>
                <button
                  className="btn btn-link btn-sm ml-3"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default TaskListItemAddTimeAmountType;
