import { faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_editTaskTime } from "../../../../services/tasks";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { editTaskTime } from "../../../../store/data/actions";
import { Task, TaskTime } from "../../../../store/data/types";
import {
  dateDiffInSeconds,
  formatDate,
  formatter,
  formatTimeSpent,
  getEarnings,
} from "../../../../Utils";
import { useInterval } from "../../../../Utils/useInterval";
import Loader from "../../../Loader";
import TaskActiveTimeDate from "./TaskActiveTimeDate";

interface IProps {
  task: Task;
  taskTime: TaskTime;
  noTimeEdit?: boolean;
}

const TaskActiveTimeListItem = ({ task, taskTime, noTimeEdit }: IProps) => {
  const dispatch = useAppDispatch();
  const { customers } = useAppSelector((app) => app.data);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { taskKey, hourlyRate, url } = task;

  const diffBetween = dateDiffInSeconds(taskTime.start, taskTime.stop);
  const [diff, setDiff] = useState(0);

  useEffect(() => {
    setDiff(0);
  }, [taskTime.start, taskTime.stop]);

  const customer = customers.find((c) => c.customerKey === task.customerKey);

  const intervalTime = 60 * 1000; //diffBetween <= 60 ? 1 * 1000 : 60 * 1000;

  useInterval(() => {
    //if stop was set, don't track
    if (taskTime.stop) return;
    setDiff(diffBetween);
  }, intervalTime);

  const stopTaskTimeAction = () => {
    setLoading(true);
    API_editTaskTime(
      taskTime.taskTimeKey,
      taskTime.start,
      Math.round(new Date().getTime() / 1000)
    )
      .then((response) => dispatch(editTaskTime(taskKey, response.data)))
      .catch((e) => {
        setError(e);
        setTimeout(() => setError(""), 4000);
      })
      .finally(() => setLoading(false));
  };

  const timeSpent =
    taskTime.stop && taskTime.durationSeconds
      ? taskTime.durationSeconds
      : diff || diffBetween; // if diff was not counted yet, get diffBetween on default

  const hasDescription =
    taskTime.description !== null && taskTime.description.length > 0;

  const startTime = () => {
    if (noTimeEdit) return formatDate(taskTime.start);

    return (
      <div className="d-flex align-items-center">
        <TaskActiveTimeDate
          taskKey={taskKey}
          taskTime={taskTime}
          toEdit={"start"}
        />
        {taskTime.type === "AMOUNT" && (
          <span
            className="badge badge-warning ml-1"
            data-tip="fixed rate regardless of time"
            data-effect="solid"
          >
            Amount
          </span>
        )}
      </div>
    );
  };

  const stopTime = () => {
    if (!taskTime.stop)
      return <span className="badge badge-success">Active</span>;
    if (noTimeEdit) return formatDate(taskTime.stop);
    return (
      <TaskActiveTimeDate
        taskKey={taskKey}
        taskTime={taskTime}
        toEdit={"stop"}
      />
    );
  };

  return (
    <React.Fragment key={`task_time_item_${taskTime.taskTimeKey}`}>
      <tr className={hasDescription ? "table-no-padding-bottom" : ""}>
        <td className="text-nowrap">{startTime()}</td>
        <td className="text-nowrap">{stopTime()}</td>
        <td>
          <small>{formatTimeSpent(timeSpent)}</small>
        </td>
        <td>
          <small>
            {taskTime.type === "AMOUNT" ? (
              <span className="task-time-amount-item">
                {formatter.format(taskTime.amount || 0)}
              </span>
            ) : (
              <>
                {taskTime.stop === null && (
                  <span className="mr-1 text-muted">~</span>
                )}
                {getEarnings([taskTime], hourlyRate)}
              </>
            )}
          </small>
        </td>
        <td>
          {error.length > 0 && (
            <div className="alert alert-danger p-0">
              <small>{error}</small>
            </div>
          )}
          {loading && <Loader />}
          {!taskTime.stop && (
            <button
              className="btn btn-sm btn-light py-1 px-2"
              onClick={stopTaskTimeAction}
            >
              <small>Stop</small>
            </button>
          )}
          {!noTimeEdit && (
            <Link
              className="py-1 px-2"
              to={`/app/C-${customer?.url}/${url}/${taskTime.taskTimeKey}`}
            >
              <FontAwesomeIcon icon={faGear} size="1x" className="text-muted" />
            </Link>
          )}
        </td>
      </tr>
      {hasDescription && (
        <tr>
          <td colSpan={5} className="pt-0">
            <div className="text-truncate small text-muted">
              {(taskTime.description || "").slice(0, 200)}
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default TaskActiveTimeListItem;
