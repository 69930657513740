import { Customer, TaskTime } from "../store/data/types";

export const emailIsValid = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const phoneIsValid = (phone: string) => phone.length === 9;

export const capitalizeFirst = (phrase: string) =>
  phrase.charAt(0).toLocaleUpperCase() + phrase.slice(1);

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const dateDiffInSeconds = (start: number, stop: number | null) => {
  const startTime = new Date(start).getTime();
  const stopTime = stop
    ? new Date(stop).getTime()
    : Math.round(new Date().getTime() / 1000);

  return stopTime - startTime;
};
export const formatDate = (date: number) => {
  const dateFixed = new Date(date * 1000);
  return (
    dateFixed.toLocaleDateString("en-US") +
    " " +
    dateFixed.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })
  );
};
export const formatTimeSpent = (timeInSec: number) => {
  if (timeInSec === 0) return "-";
  if (timeInSec < 60) return `< 1min`;
  if (timeInSec < 3600) return `${Math.round(timeInSec / 60)} min`;
  const fullHours = Math.floor(timeInSec / 3600);
  const minutes = Math.round((timeInSec - 3600 * fullHours) / 60);
  return `${fullHours}hr ${minutes}min`;
};

export const getEarningsByTime = (
  hourlyRate: number,
  spentTaskTimeByTime: number
) =>
  Math.round((hourlyRate / 60) * Math.round(spentTaskTimeByTime / 60) * 100) /
  100;

export const getEarnings = (taskTimeItems: TaskTime[], hourlyRate: number) => {
  const spentTaskTimeByTime = taskTimeItems
    .filter((t) => t.type === "TIME")
    .reduce((initial, taskTime) => {
      // duration depends on taskTime was closed or not
      const duration =
        taskTime.durationSeconds !== 0
          ? taskTime.durationSeconds
          : new Date().getTime() / 1000 - taskTime.start;
      return initial + Math.round(duration);
    }, 0);
  const earningsByTime = getEarningsByTime(hourlyRate, spentTaskTimeByTime);

  const earningsByAmount = taskTimeItems
    .filter((t) => t.type === "AMOUNT")
    .reduce((initial, taskTime) => initial + (taskTime.amount || 0), 0);

  return formatter.format(earningsByTime + earningsByAmount);
};

export const urlGenerator = (url: string, prevUrl?: string) => {
  const updatedUrl = url
    // eslint-disable-next-line no-control-regex
    .replace(/[^\x00-\x7F]/g, "")
    .replace(/\W/g, " ")
    .replace(/\s\s+/g, " ")
    .split(" ")
    .filter((phrase) => phrase.length)
    .map((phrase) => phrase[0].toUpperCase())
    .join("");
  if (prevUrl) {
    // validate if need to update
    const prevUrlToValidate = prevUrl.replace(/\W/g, "").replace(/[0-9]/g, "");
    if (updatedUrl === prevUrlToValidate) return prevUrl;
  }
  return updatedUrl;
};

export const getCustomerName = (
  customerList: Customer[],
  customerKey: string,
  fullString?: boolean
) => {
  const customer = customerList.find((c) => c.customerKey === customerKey);
  if (!customer) return null;
  let customerName = customer.name;
  if (fullString) return customerName;
  if (customerName.length > 15) {
    customerName = `${customerName.slice(0, 15)}...`;
  }
  return customerName;
};
