import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { useAppSelector } from "../../../../../store";
import { Customer, Task } from "../../../../../store/data/types";
import { Report } from "../../../../../store/reports/types";
import TaskListHeader from "../../Task/TaskListHeader";
import TaskListItem from "../../Task/TaskListItem";
import TasksNoReportsAssignToExistingReportBtn from "./TasksNoReportsAssignToExistingReportBtn";
import TasksNoReportsNewReportBtn from "./TasksNoReportsNewReportBtn";

interface IProps {
  customer: Customer;
  reports: Report[];
  addNewReport: (newReport: Report) => void;
  updateReport: (updatedReport: Report) => void;
}

const TasksNoReportsPerCustomer = ({
  customer,
  reports,
  addNewReport,
  updateReport,
}: IProps) => {
  const { tasks } = useAppSelector((app) => app.data);
  const [selectedTasksKeys, setSelectedTasksKeys] = useState<string[]>([]);

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const taskKey = e.currentTarget.dataset.id;
    if (!taskKey) return;
    const selectedKeys = selectedTasksKeys.find((key) => key === taskKey)
      ? selectedTasksKeys.filter((key) => key !== taskKey)
      : [...selectedTasksKeys, taskKey];
    setSelectedTasksKeys(selectedKeys);
  };

  const activeTasksForCustomer = tasks.filter(
    (t) => t.customerKey === customer.customerKey
  );
  if (!activeTasksForCustomer.length) return null;
  const customerReports = reports.filter(
    (r) => r.customerKey === customer.customerKey
  );

  const taskItem = (task: Task) => {
    const isChecked =
      selectedTasksKeys.find((key) => key === task.taskKey) !== undefined;
    const taskActiveTimes = task.taskTimes.filter((tt) => !tt.stop);
    return (
      <tr key={`task_item_${task.taskKey}`}>
        <td>
          <div className="form-check inbox-check">
            <input
              className="form-check-input"
              data-id={task.taskKey}
              onChange={handleCheckbox}
              type="checkbox"
              checked={isChecked}
              id={`report_task_${task.taskKey}`}
              disabled={taskActiveTimes.length > 0}
            />
            <label
              className="form-check-label"
              htmlFor={`report_task_${task.taskKey}`}
            ></label>
            {taskActiveTimes.length > 0 && (
              <>
                <FontAwesomeIcon
                  data-html={true}
                  icon={faQuestion}
                  data-tip={`This task has <strong>${taskActiveTimes.length}</strong> active item(s). Stop them to add this task to the report.`}
                  className="position-absolute report-disabled-checkbox-info-tooltip"
                />
                <ReactTooltip />
              </>
            )}
          </div>
        </td>
        <TaskListItem task={task} hideTaskControls={true} noTrItem={true} />
      </tr>
    );
  };

  return (
    <li
      className="list-group-item p-0 mb-3 border"
      key={`reports_for_customer_${customer.customerKey}`}
    >
      <div className="border-bottom bg-light pl-2 py-1">{customer.name}</div>
      <table className="table freelance-table table-responsive">
        <TaskListHeader
          hideTaskControls={true}
          customFirstHeader={
            <th className="report-select-task-checkbox-header text-center">
              #
            </th>
          }
        />
        <tbody>{activeTasksForCustomer.map(taskItem)}</tbody>
      </table>

      <div className="mb-3 ml-2">
        <TasksNoReportsNewReportBtn
          selectedTasksKeys={selectedTasksKeys}
          addNewReport={addNewReport}
          customerKey={customer.customerKey}
        />
        <TasksNoReportsAssignToExistingReportBtn
          selectedTasksKeys={selectedTasksKeys}
          customerReports={customerReports}
          updateReport={updateReport}
        />
        {!selectedTasksKeys.length && (
          <div className="small text-muted">Plase select at least one task</div>
        )}
      </div>
    </li>
  );
};

export default TasksNoReportsPerCustomer;
