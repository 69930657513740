import React, { useState } from "react";
import {
  faGear,
  faClipboardCheck,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Report } from "../../../../../store/reports/types";
import { APP_CUSTOMER_URL } from "../../../../../Utils/constants";
import { ModalWindow } from "../../../Common";
import ManageExistingReportsItemModal from "./ManageExistingReportsItemModal";
import ManageExistingReportsItemDetailsLongText from "./ManageExistingReportsItemDetailsLongText";

interface IProps {
  report: Report;
  updateReport: (updatedReport: Report) => void;
}
const ManageExistingReportsItem = ({ report, updateReport }: IProps) => {
  const [urlCopied, setUrlCopied] = useState(false);
  const [showReportDetail, setShowReportDetail] = useState(false);
  const toggleShowReportDetail = () => setShowReportDetail(!showReportDetail);

  const copyUrl = (event: React.MouseEvent<HTMLButtonElement>) => {
    const url = event.currentTarget.dataset.url;
    if (!url) return;
    navigator.clipboard.writeText(url);
    setUrlCopied(true);
    setTimeout(() => setUrlCopied(false), 3000);
  };

  return (
    <li
      className="list-group-item  flex-column d-flex align-items-start"
      key={`report_list_item_${report.reportKey}`}
    >
      <button
        className="btn btn-sm btn-link p-0 d-flex flex-column flex-md-row align-items-start align-items-md-center text-black text-left w-100"
        onClick={toggleShowReportDetail}
      >
        <div>
          {report.title.length > 0 ? `${report.title}, ` : "Report "} generated
          on {report.generatedTime}
        </div>
        <div className="text-right ml-auto ml-md-2">
          <span className="ml-1">
            <strong>
              <small>
                ({report.taskKeyList.length}{" "}
                {report.taskKeyList.length > 1 ? "task" : "tasks"})
              </small>
            </strong>
          </span>
          <FontAwesomeIcon icon={faGear} className="small text-muted ml-1" />
        </div>
      </button>
      <div className="text-muted small">
        <ManageExistingReportsItemDetailsLongText text={report.description} />
      </div>
      <div className="input-group mt-1">
        <input
          type="text"
          className="form-control form-control-sm"
          defaultValue={`${APP_CUSTOMER_URL}${report.url}`}
        />
        <div className="input-group-append">
          <button
            className="btn btn-sm px-3 btn-outline-light"
            onClick={copyUrl}
            data-url={`${APP_CUSTOMER_URL}${report.url}`}
          >
            <FontAwesomeIcon
              icon={urlCopied ? faClipboardCheck : faClipboard}
            />
          </button>
        </div>
      </div>
      {urlCopied && <div className="small text-right">Copied!</div>}
      {showReportDetail && (
        <ModalWindow closeModalCustomAction={toggleShowReportDetail}>
          <ManageExistingReportsItemModal
            report={report}
            updateReport={updateReport}
          />
        </ModalWindow>
      )}
    </li>
  );
};

export default ManageExistingReportsItem;
