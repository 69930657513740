import React, { useState } from "react";
import { API_addNewCustomer } from "../../../../services/customers";
import { useAppDispatch } from "../../../../store";
import { addCustomers } from "../../../../store/data/actions";
import { Customer } from "../../../../store/data/types";
import { urlGenerator } from "../../../../Utils";
import Loader from "../../../Loader";

interface IProps {
  customers: Customer[];
  toggleAddNewCustomerContainer: () => void;
  updateCustomerKey: (customerKey: string) => void;
}

const TaskFormCustomerSelectAddNewCustomer = ({
  customers,
  toggleAddNewCustomerContainer,
  updateCustomerKey,
}: IProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [customerName, setCustomerName] = useState("");

  const addNewCustomerAction = () => {
    setLoading(true);
    const customerUrl = urlGenerator(customerName);
    API_addNewCustomer(customerName, "", "", customerUrl)
      .then((response) => {
        dispatch(addCustomers([response.data]));
        setLoading(false);
        updateCustomerKey(response.data.customerKey);
        toggleAddNewCustomerContainer();
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
        setTimeout(() => {
          setError("");
        }, 4000);
      });
  };

  const setName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    // check if this name is not taken
    const isUnique =
      customers.find(
        (c) => c.name.toLocaleLowerCase() === name.toLocaleLowerCase()
      ) === undefined;
    if (!isUnique) {
      setError("You already have a customer with that name");
    } else if (error.length) {
      setError("");
    }
    setCustomerName(name);
  };

  if (loading) return <Loader small />;
  return (
    <div className="dropdown-menu show w-100 px-2">
      {error.length > 0 && (
        <div className="alert alert-danger alert-sm">{error}</div>
      )}
      <div>
        <label className="form-label h6" htmlFor="EmailLabel">
          Enter the name of the new customer
        </label>

        <input
          type="text"
          value={customerName}
          onChange={setName}
          className="form-control form-control-sm"
        />
      </div>
      <div className="mt-2 d-flex">
        <button
          className="btn btn-sm btn-primary"
          onClick={addNewCustomerAction}
          disabled={customerName.length === 0 || error.length > 0}
        >
          Add new
        </button>
        <button
          className="btn btn-sm btn-link"
          onClick={toggleAddNewCustomerContainer}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default TaskFormCustomerSelectAddNewCustomer;
