import axios from "axios";
import { API } from "../config/constants";
import {
  CheckUrlAvailability,
  Task,
  TaskTime,
  TaskTimeType,
} from "../store/data/types";
import "./helper";

export const API_getTaskListByCustomer = (customerKey: string) =>
  axios.post<Task[]>(`${API}tasks`, {
    action: "get_task_list_by_customer",
    customerKey,
  });

export const API_editTask = (
  taskKey: string,
  name: string,
  description: string,
  hourlyRate: string,
  customerKey: string,
  taskUrl: string
) =>
  axios.put<Task>(`${API}tasks`, {
    action: "edit_task",
    taskKey,
    name,
    description,
    hourlyRate,
    customerKey,
    taskUrl,
  });

export const API_addNewTask = (
  customerKey: string,
  name: string,
  description: string,
  hourlyRate: string,
  taskUrl: string
) =>
  axios.post<Task>(`${API}tasks`, {
    action: "add_task",
    customerKey,
    name,
    description,
    hourlyRate,
    taskUrl,
  });

export const API_removeTask = (taskKey: string, customerKey: string) =>
  axios.post<Task>(`${API}tasks`, {
    action: "remove_task",
    taskKey,
    customerKey,
  });

export const API_checkTaskUrlAvailability = (
  taskUrl: string,
  taskKey?: string
) =>
  axios.post<CheckUrlAvailability>(`${API}tasks`, {
    action: "check_task_url_availability",
    taskUrl,
    taskKey,
  });

export const API_addNewTaskTime = (
  taskKey: string,
  type: TaskTimeType,
  amount?: string,
  description?: string
) =>
  axios.post<TaskTime>(`${API}tasks`, {
    action: "add_task_time",
    taskKey,
    type,
    amount,
    description,
  });

export const API_editTaskTime = (
  taskTimeKey: string,
  start: number,
  stop: number | null,
  description?: string | null,
  amount?: string
) =>
  axios.post<TaskTime>(`${API}tasks`, {
    action: "edit_task_time",
    taskTimeKey,
    start,
    stop,
    description,
    amount,
  });
export const API_removeTaskTime = (taskTimeKey: string) =>
  axios.post<TaskTime>(`${API}tasks`, {
    action: "remove_task_time",
    taskTimeKey,
  });

// for reports
export const API_getTaskListByKeys = (taskKeys: string[]) =>
  axios.post<Task[]>(`${API}tasks`, {
    action: "get_task_list_by_keys",
    taskKeys,
  });
