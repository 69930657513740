import React, { useEffect, useState } from "react";
import {
  API_getStatisticsYearly,
  StatsType,
} from "../../../../services/statistics";
import { YearlyStats } from "../../../../store/statistics/types";
import Loader from "../../../Loader";
import ChartComponent from "./Chart";

const Statistics = () => {
  const [statsYearly, setStatsYearly] = useState<YearlyStats>([]);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState("");
  const [statsType, setStatsType] = useState<StatsType>("all");

  useEffect(() => {
    console.log("looooad stats data");
    API_getStatisticsYearly(new Date().getFullYear(), statsType)
      .then((response) => {
        setStatsYearly(response.data);
        setLoader(false);
      })
      .catch((errorMessage) => {
        setLoader(false);
        setError(errorMessage);
      });
  }, [statsType]);

  const updateStatsType = (event: React.ChangeEvent<HTMLInputElement>) =>
    setStatsType(event.target.checked ? "active" : "all");

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 d-flex justify-content-end">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="include-list-task-times"
              onChange={updateStatsType}
              checked={statsType === "active"}
            />
            <label
              className="form-check-label"
              htmlFor="include-list-task-times"
            >
              Show only active (not settled, no report) tasks
            </label>
          </div>
        </div>
        <div className="col-12">
          {error.length > 0 && (
            <div className="card bg-white border-light">
              <div className="card-body p-4">
                <div className="alert alert-danger">{error}</div>
              </div>
            </div>
          )}
          {loader ? <Loader /> : <ChartComponent statsYearly={statsYearly} />}
        </div>
      </div>
    </div>
  );
};
export default Statistics;
