import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../store";
import { Task } from "../../../../store/data/types";
import {
  dateDiffInSeconds,
  formatter,
  formatTimeSpent,
  getEarnings,
} from "../../../../Utils";

interface IProps {
  taskDetail: Task;
  toggleEdit: () => void;
}

const TaskDetailPreview = ({ taskDetail, toggleEdit }: IProps) => {
  const { customers } = useAppSelector((app) => app.data);

  const customerDetail = customers.find(
    (c) => c.customerKey === taskDetail?.customerKey
  );

  const timeSpent = taskDetail.taskTimes.reduce(
    (initial, taskTime) => initial + taskTime.durationSeconds,
    0
  );
  const timeSpentAllTasks = taskDetail.taskTimes.reduce(
    (initial, taskTime) =>
      initial + dateDiffInSeconds(taskTime.start, taskTime.stop),
    0
  );
  const finishedTaskTimes = taskDetail.taskTimes.filter((t) => t.stop !== null);

  return (
    <>
      <table className="table table-responsive freelancer-table small mb-0">
        <tbody>
          <tr>
            <td className="">Name:</td>
            <td>{taskDetail.name}</td>
          </tr>
          <tr>
            <td>Description:</td>
            <td>{taskDetail.description}</td>
          </tr>
          <tr>
            <td>Customer:</td>
            <td>
              <Link className="cut-text" to={`/app/C-${customerDetail?.url}`}>
                {customerDetail?.name}
              </Link>
            </td>
          </tr>
          <tr>
            <td>Hourly rate:</td>
            <td>{formatter.format(taskDetail.hourlyRate)}</td>
          </tr>
          <tr>
            <td>
              Time spent
              <br />
              <span className="text-nowrap">(closed tasks):</span>
            </td>
            <td>{formatTimeSpent(timeSpent)}</td>
          </tr>
          <tr>
            <td>
              Time spent
              <br />
              <span className="text-nowrap">(all tasks):</span>
            </td>
            <td>{formatTimeSpent(timeSpentAllTasks)}</td>
          </tr>
          <tr>
            <td>
              Earnings
              <br />
              <span className="text-nowrap">(closed tasks):</span>
            </td>
            <td>{getEarnings(finishedTaskTimes, taskDetail.hourlyRate)}</td>
          </tr>
          <tr>
            <td>
              Earnings
              <br />
              <span className="text-nowrap">(all tasks):</span>
            </td>
            <td>{getEarnings(taskDetail.taskTimes, taskDetail.hourlyRate)}</td>
          </tr>
        </tbody>
      </table>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-sm text-muted font-weight-normal"
          onClick={toggleEdit}
        >
          <small>
            Edit this task <FontAwesomeIcon icon={faEdit} />
          </small>
        </button>
      </div>
    </>
  );
};

export default TaskDetailPreview;
