import React, { useEffect, useState } from "react";
import CalendarWeek from "./CalendarWeek";
import "./calendar.css";
import Loader from "../../../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";

export type FirstDateOfTheCurrentWeek = {
  firstWeekDay: number;
  firstWeekMonth: number;
  firstWeekYear: number;
};

const Calendar = () => {
  const [firstDateOfTheCurrentWeek, setFirstDateOfTheCurrentWeek] = useState<
    FirstDateOfTheCurrentWeek | undefined
  >(undefined);

  const setfirstDateObj = (date: Date) => {
    const firstWeekDay = date.getDate() - date.getDay(); // First day is the day of the month - the day of the week
    const firstWeekMonth = date.getMonth() + 1;
    const firstWeekYear = date.getFullYear();
    return {
      firstWeekDay,
      firstWeekMonth,
      firstWeekYear,
    };
  };

  useEffect(() => {
    setFirstDateOfTheCurrentWeek(setfirstDateObj(new Date()));
  }, []);

  const switchWeek = (e: React.MouseEvent<HTMLButtonElement>) => {
    const action = e.currentTarget.dataset.action;
    if (!action) return;
    if (action === "current") {
      setFirstDateOfTheCurrentWeek(setfirstDateObj(new Date()));
      return;
    }
    const days = action === "prev" ? -7 : 7;

    const newDate = new Date(
      firstDateOfTheCurrentWeek!.firstWeekYear,
      firstDateOfTheCurrentWeek!.firstWeekMonth - 1,
      firstDateOfTheCurrentWeek!.firstWeekDay
    );
    newDate.setDate(newDate.getDate() + days);
    setFirstDateOfTheCurrentWeek(setfirstDateObj(newDate));
  };

  if (!firstDateOfTheCurrentWeek) return <Loader />;
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="row">
              <div className="col-12">
                <button
                  className="btn bt-sm"
                  onClick={switchWeek}
                  data-action="prev"
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button
                  className="btn bt-sm"
                  onClick={switchWeek}
                  data-action="current"
                >
                  <FontAwesomeIcon icon={faSquare} />
                </button>
                <button
                  className="btn bt-sm"
                  onClick={switchWeek}
                  data-action="next"
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </div>
            <div className="col-12">
              <CalendarWeek
                firstDateOfTheCurrentWeek={firstDateOfTheCurrentWeek}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Calendar;
