import { ReactNode, useEffect, useRef, useState } from "react";
import { API_getCustomerList } from "../../../services/customers";
import { API_getTaskListByCustomer } from "../../../services/tasks";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setAppDataLoaded } from "../../../store/app/actions";
import { addCustomers, addTasks } from "../../../store/data/actions";
import Loader from "../../Loader";

interface IProps {
  children: ReactNode;
}

const ApplicationLoader = ({ children }: IProps) => {
  const { appDataLoaded } = useAppSelector((app) => app.app);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const didLogRef = useRef(false);

  useEffect(() => {
    const loadBaseAppData = async () => {
      const customerList = await API_getCustomerList();
      dispatch(addCustomers(customerList.data));

      const tasksPromises = customerList.data.map((customer) =>
        API_getTaskListByCustomer(customer.customerKey)
      );

      const taskList = await Promise.all(tasksPromises);
      taskList.forEach((tasksPerCustomer) =>
        dispatch(addTasks(tasksPerCustomer.data))
      );

      dispatch(setAppDataLoaded(true));
      setLoading(false);
    };

    if (didLogRef.current === false && appDataLoaded === false) {
      didLogRef.current = true;
      loadBaseAppData();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? <Loader /> : <>{children}</>;
};

export default ApplicationLoader;
