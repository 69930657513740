import { faPlus, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { TaskTimeType } from "../../../../store/data/types";
import { ModalWindow } from "../../Common";
import TaskListItemAddTimeAmountType from "./TaskListItemAddTimeAmountType";

interface IProps {
  addTaskTimeAction: (
    type: TaskTimeType,
    amount?: string,
    description?: string
  ) => void;
  toggleMoreMenu: () => void;
}

const TaskListItemAddTimeMoreOptions = ({
  addTaskTimeAction,
  toggleMoreMenu,
}: IProps) => {
  const [tooltip, showTooltip] = useState(true);
  const [showAddTaskTimeAmount, setShowAddTaskTimeAmount] = useState(false);
  const toggleShowAddTaskTimeAmount = () =>
    setShowAddTaskTimeAmount(!showAddTaskTimeAmount);

  return (
    <>
      <div className="position-absolute d-flex align-items-center toggle-menu">
        <button
          className="btn btn-sm btn-light py-1 px-1 d-flex toggle-menu-container-selector"
          onClick={toggleShowAddTaskTimeAmount}
        >
          <small>Add `amount` type</small>{" "}
          <FontAwesomeIcon icon={faPlus} size="1x" className="ml-1" />
        </button>
        <span
          className="toggle-menu-container-selector"
          data-tip="By default you track your work by time.<br />But you can also add a custom task time item<br /> by amount no matter how long you work."
          data-effect="solid"
          data-multiline="true"
          onMouseEnter={() => showTooltip(true)}
          onMouseLeave={() => {
            showTooltip(false);
            setTimeout(() => showTooltip(true), 50);
          }}
        >
          <small>
            <FontAwesomeIcon
              icon={faQuestionCircle}
              size="1x"
              className="ml-2"
            />
          </small>
        </span>

        {tooltip && <ReactTooltip />}
      </div>
      <div
        className="close-toggle-menu-backdrop"
        onClick={toggleMoreMenu}
      ></div>
      {showAddTaskTimeAmount && (
        <ModalWindow closeModalCustomAction={toggleShowAddTaskTimeAmount}>
          <TaskListItemAddTimeAmountType addTaskTimeAction={addTaskTimeAction} />
        </ModalWindow>
      )}
    </>
  );
};

export default TaskListItemAddTimeMoreOptions;
