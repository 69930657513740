import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { ModalWindow } from "./Common";
import CustomerAddNew from "./Components/Customer/CustomerAddNew";
import CustomerDetail from "./Components/Customer/CustomerDetail";
import Dashboard from "./Components/Dashboard";
import AddNewTask from "./Components/Task/AddNewTask";
import TaskDetail from "./Components/Task/TaskDetail";
import TaskTimeFormEdit from "./Components/Task/TaskTimeFormEdit";
import Footer from "./core/Footer";
import MobileHeader from "./core/MobileHeader";
import SidebarMenu from "./core/SidebarMenu";
import Reports from "./Components/Reports";
import Account from "./Components/Account";
import ReactTooltip from "react-tooltip";
import Calendar from "./Components/Calendar";
import Statistics from "./Components/Statistics";

const App = () => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const toggleMobileMenuVisible = () =>
    setMobileMenuVisible(!mobileMenuVisible);
  const closeMobileMenuVisible = () => setMobileMenuVisible(false);
  return (
    <>
      <MobileHeader toggleMobileMenuVisible={toggleMobileMenuVisible} />

      <div className="container-fluid bg-soft main-container d-flex flex-column">
        <div className="row">
          <div className="col-12">
            <SidebarMenu
              mobileMenuVisible={mobileMenuVisible}
              toggleMobileMenuVisible={toggleMobileMenuVisible}
              closeMobileMenuVisible={closeMobileMenuVisible}
            />
            <main className="content">
              <div className="row">
                <div className="col-12 mb-4 mt-2">
                  <Routes>
                    <Route path="/reports" element={<Reports />} />
                    <Route path="/account/*" element={<Account />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="C-:customerUrl" element={<CustomerDetail />} />
                    <Route path="/statistics" element={<Statistics />} />
                    <Route path="/" element={<Dashboard />} />

                    {/* <Route path="/app/templates" component={Templates} />
                    <Route path="/app/keys" component={ApiKeys} />
                    <Route path="/app/account" component={Account} />
                    <Route path="/app/usage" component={UsageHistory} /> */}
                  </Routes>
                </div>
              </div>
            </main>
          </div>
        </div>
        <Footer />
      </div>
      <Routes>
        <Route
          path="add-customer"
          element={
            <ModalWindow>
              <CustomerAddNew />
            </ModalWindow>
          }
        />
        <Route
          path="add-task"
          element={
            <ModalWindow>
              <AddNewTask />
            </ModalWindow>
          }
        />
        <Route
          path="C-:customerUrl/:taskUrl"
          element={
            <ModalWindow>
              <TaskDetail />
            </ModalWindow>
          }
        />
        <Route
          path="C-:customerUrl/:taskUrl/:taskTimeKey"
          element={
            <ModalWindow>
              <TaskTimeFormEdit />
            </ModalWindow>
          }
        />
      </Routes>
      <ReactTooltip />
    </>
  );
};

export default App;
