import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import Loader from "../../../Loader";
import { useAppDispatch } from "../../../../store";
import { addTasks } from "../../../../store/data/actions";
import TaskForm from "./TaskForm";
import { ModalProps } from "../../Common/Modal/ModalWindow";
import { API_addNewTask } from "../../../../services/tasks";

interface IProps extends ModalProps {
  formAsButton?: boolean;
}

const AddNewTask = ({ formAsButton, setTitle, closeModal }: IProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [addNew, setAddNew] = useState(false);
  const toggleAddNew = () => setAddNew(!addNew);

  useEffect(() => {
    if (setTitle) setTitle("Add new task");
  }, [setTitle]);

  const addNewTaskAction = (
    customerKey: string,
    name: string,
    hourlyRate: string,
    description: string,
    taskUrl: string
  ) => {
    setLoading(true);

    API_addNewTask(customerKey, name, description, hourlyRate, taskUrl)
      .then((response) => {
        setAddNew(false);
        dispatch(addTasks([response.data]));
        if (closeModal) closeModal();
      })
      .catch((e) => {
        setError(e);
        setTimeout(() => {
          setError("");
        }, 4000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) return <Loader />;
  return addNew || !formAsButton ? (
    <>
      {error.length > 0 && <div className="alert alert-danger">{error}</div>}
      <TaskForm saveTaskAction={addNewTaskAction} saveDataTxt="Add task" />
    </>
  ) : (
    <button className="btn btn-sm btn-light" onClick={toggleAddNew}>
      Add a new task <FontAwesomeIcon icon={faPlus} />
    </button>
  );
};

export default AddNewTask;
