import React from "react";
import { Provider } from "react-redux";
import AppRouter from "./config/Router";
import AppError from "./AppError";
import { store } from "./store";

const App = () => (
  <Provider store={store}>
    <AppError>
      <AppRouter />
    </AppError>
  </Provider>
);

export default App;
