import { useState } from "react";
import { formatDate } from "../../../../Utils";
import DateTimePicker from "react-datetime-picker";
import Loader from "../../../Loader";
import { TaskTime } from "../../../../store/data/types";
import { API_editTaskTime } from "../../../../services/tasks";
import { useAppDispatch } from "../../../../store";
import { editTaskTime } from "../../../../store/data/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import React from "react";

interface IProps {
  taskTime: TaskTime;
  taskKey: string;
  toEdit: "start" | "stop";
}

const TaskActiveTimeDate = ({ taskTime, taskKey, toEdit }: IProps) => {
  const dispatch = useAppDispatch();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string[]>([]);
  const [updatedValue, setUpdatedValue] = useState<Date | null>(null);

  const toggleEdit = () => {
    const newState = !edit;
    setEdit(!edit);
    setError([]);
    if (updatedValue && newState === false) {
      setLoading(true);
      const taskTimeStart =
        toEdit === "start"
          ? Math.round(updatedValue.getTime() / 1000)
          : taskTime.start;
      const taskTimeStop =
        toEdit === "stop"
          ? Math.round(updatedValue.getTime() / 1000)
          : taskTime.stop;
      API_editTaskTime(taskTime.taskTimeKey, taskTimeStart, taskTimeStop)
        .then((response) => {
          dispatch(editTaskTime(taskKey, response.data));
        })
        .catch((e) => {
          setError([e]);
          setTimeout(() => setError([]), 4000);
        })
        .finally(() => setLoading(false));
    }
  };
  const onChange = (value: Date) => {
    const updatedTaskTime = value.getTime() / 1000;
    const editedTaskTime = taskTime[toEdit];
    if (!editedTaskTime) return;

    // validate if needed
    if (
      toEdit === "start" &&
      taskTime.stop &&
      updatedTaskTime > taskTime.stop
    ) {
      setError(["The start date cannot be", "later than the end date"]);
      return;
    }
    if (toEdit === "stop" && updatedTaskTime < taskTime.start) {
      setError(["The end date cannot be", "earlier than the start date"]);
      return;
    }
    setError([]);
    setUpdatedValue(value);
  };

  const saveActionButtonIcon = () => {
    if (error.length) return <FontAwesomeIcon icon={faCancel} />;
    if (edit) return <FontAwesomeIcon icon={faSave} />;
    return <FontAwesomeIcon icon={faEdit} />;
  };

  if (loading) return <Loader small />;
  return (
    <>
      <div>
        <small>
          {edit ? (
            <DateTimePicker
              onChange={onChange}
              value={updatedValue || new Date((taskTime[toEdit] || 0) * 1000)}
              disableClock
              calendarIcon={null}
              clearIcon={null}
              format={"M/d/y h:mm a"}
              className="freelace-calendar"
            />
          ) : (
            formatDate(taskTime[toEdit] || 0)
          )}
          <button
            className="btn btn-sm d-inline-flex small align-items-center"
            onClick={toggleEdit}
          >
            {saveActionButtonIcon()}
          </button>
        </small>
      </div>

      {error.length > 0 && (
        <div className="alert alert-danger px-1 py-0">
          <small>
            {error.map((err) => (
              <React.Fragment key={err}>
                {err}
                <wbr />
              </React.Fragment>
            ))}
          </small>
        </div>
      )}
    </>
  );
};

export default TaskActiveTimeDate;
