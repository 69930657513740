import React, { useEffect, useRef, useState } from "react";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  searchText: string;
  setSearchText: (text: string) => void;
}

const TaskListFilterResultsBox = ({ searchText, setSearchText }: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const toggleEnabled = () => setSearchEnabled(!searchEnabled);

  const cancelSearch = () => {
    setSearchText("");
    toggleEnabled();
  };

  useEffect(() => {
    if (searchEnabled && inputRef.current) inputRef.current.focus();
  }, [searchEnabled]);

  const searchTextUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  return (
    <div className="search d-flex align-items-center mr-3">
      {searchEnabled ? (
        <div className="d-flex">
          <div className="input-group">
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Customer or task name"
              onChange={searchTextUpdate}
              value={searchText}
              ref={inputRef}
            />{" "}
            <div className="input-group-append">
              <div className="input-group-text p-0">
                <button
                  className="btn btn-sm font-small text-muted"
                  onClick={cancelSearch}
                >
                  <FontAwesomeIcon icon={faClose} className="small" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <button
          className="btn btn-sm btn-outline-light font-small text-muted"
          onClick={toggleEnabled}
        >
          <FontAwesomeIcon icon={faSearch} className="small mr-1" />
          Filter
        </button>
      )}{" "}
    </div>
  );
};

export default TaskListFilterResultsBox;
