import React from "react";
import BaseUserDataForm from "./BaseUserDataForm";
import InvoiceDataForm from "./InvoiceDataForm";

const AccountForm = () => (
  <>
    <BaseUserDataForm />
    <InvoiceDataForm />
  </>
);

export default AccountForm;
